import Modal from "./Modal";

const FilePreviewModal = ({isShown, onClose, fileType, fileUrl}) => {
    return (
        <Modal title={'File Preview Modal'} className={'modal-auto'} isShown={isShown} onClose={onClose}>
            <Modal.Body>
                <div className="preview-modal">
                    {
                        fileType === "image" ?
                            <img src={fileUrl} alt={'Preview'}/> :
                            (
                                fileType === 'video' ?
                                    <video src={fileUrl} controls /> :
                                    <p className="empty-message">
                                        Invalid File Type
                                    </p>
                            )
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilePreviewModal;