import {MdCheck, MdEast} from "react-icons/md";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader";
import {sendPost} from "../../../common/request";
import links from "../../../common/links";
import MessageModal from "../Modal/MessageModal";
import ConfirmationModal from "../Modal/ConfirmationModal";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import currencyFormat from "../../../common/format";

const PricingSection = ({canViewFeatureComparison}) => {

    const [currentPeriod, setCurrentPeriod] = useState('month');
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [heading, setHeading] = useState('');
    const [downgradeMessage, setDowngradeMessage] = useState('');
    const [downgradePackage, setDowngradePackage] = useState('');
    const {user, loading: userLoading} = useSelector(state => state.user);
    const {packages, loading: packagesLoading} = useSelector(state => state.data);
    const {t, i18n } = useTranslation();
    const featureComparison = [
        {
            name: "",
            features: [
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.0"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.1"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.2"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.3"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.4"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.5"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.6"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.7"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.8"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.9"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.10"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.11"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.12"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.13"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.14"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.15"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.16"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.17"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.18"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.19"), "Max 2", "Unlimited"],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.20"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.21"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.22"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.23"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.24"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.25"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.26"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.27"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.28"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.29"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.30"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.31"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.32"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.33"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.34"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.35"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.36"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.37"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.38"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.39"), false, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.40"), true, true],
                [t("landing_page.pricing_section.feature_comparison.groups.0.features.41"), false, true]
            ]
        },
    ]

    const userData = user?.user;

    const onGetStarted = async (packageName) => {

        let purpose = 'new';

        if(userData && userData.subscription) {
            const previousPackage = packages.filter(pck => pck.name === userData.subscription.package)[0]
            const newPackage = packages.filter(pck => pck.name === packageName)[0]

            if(userData.subscription.package === packageName && userData.subscription.period === currentPeriod) {
                return;
            }

            if(previousPackage && newPackage && previousPackage.price[userData.subscription.period] > newPackage.price[currentPeriod]) {
                setDowngradeMessage(`Are you sure you want to downgrade from ${previousPackage.name} to ${newPackage.name}`);
                setDowngradePackage(newPackage.name);
            }
            else {
                purpose = 'change'
                window.location.href = `/payment/${packageName}/${currentPeriod}/${purpose}`;
            }
        }
        else {
            window.location.href = `/payment/${packageName}/${currentPeriod}/${purpose}`;
        }
    }

    const onConfirmDowngrade = async () => {
        setDowngradeMessage('')
        const response = await sendPost(links.DowngradeSubscription, {packageName: downgradePackage, period: currentPeriod}, true, 'application/json', true);
        if(response.error) {
            setHeading('Unable to Downgrade Subscription');
            setMessage(response.error);
        }
        else {
            setHeading('Downgrade Successful');
            setMessage(response.message);
        }
        setDowngradePackage('');
    }

    const onCloseConfirmationModal = () => {
        setDowngradeMessage('')
        setDowngradePackage('');
    }

    return (
        <div className='pricing-section front' id='pricing'>
                <MessageModal message={message} heading={heading} handleCloseModal={() => setMessage('')} isOpen={message !== ''} />
                {
                    downgradeMessage ?
                        <ConfirmationModal heading={t("landing_page.pricing_section.package_downgrade.heading")} message={downgradeMessage} handleCloseModal={onCloseConfirmationModal} handleConfirmModal={onConfirmDowngrade} /> : <></>
                }
                <div className="content">
                    <div className="title">
                        <div className="heading">{t("landing_page.pricing_section.title")}</div>
                        <div className="description">{t("landing_page.pricing_section.description")}</div>
                    </div>
                    {
                        packagesLoading ?
                            <Loader/> :
                            <div className="pricing">
                                <div className="button-group rounded">
                                    <div className={`button ${currentPeriod === 'month' ? 'active' : ''}`}
                                         onClick={() => setCurrentPeriod('month')}>{t("landing_page.pricing_section.monthly_button")}
                                    </div>
                                    <div className={`button ${currentPeriod === 'year' ? 'active' : ''}`}
                                         onClick={() => setCurrentPeriod('year')}>{t("landing_page.pricing_section.yearly_button")}
                                    </div>
                                </div>
                                <div className="packages">
                                    {
                                        packages.map((pack, index) => {
                                            return (
                                                <div className={`package ${pack.packageClass}`} key={index}>
                                                    <div className="package-content">
                                                        <div className="top-section">
                                                            {
                                                                pack.popular ?
                                                                    <div className="tag-container">
                                                                        <div className="popular-tag">{t("landing_page.pricing_section.popular")}</div>
                                                                    </div> : <></>
                                                            }
                                                            <div className="title">{pack.localizedContent[i18n.language]?.name}</div>
                                                            <div className="description">{pack.localizedContent[i18n.language]?.description}</div>
                                                        </div>
                                                        <div className="price-section">
                                                            <div className="price-row">
                                                            <span
                                                                className='price'>${pack.price[currentPeriod].toLocaleString()}</span>
                                                                <span>per {currentPeriod}</span>
                                                            </div>
                                                            <div className="discount-row">
                                                                {
                                                                    pack.discount && pack.discount[currentPeriod] ?
                                                                        <div><strong>{currencyFormat.format((pack.price['month'] - ((pack.discount[currentPeriod]?.firstYear * pack.price['month']) / 100)) * 12)}</strong> for first year</div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className="rounded-button"
                                                                 onClick={() => onGetStarted(pack.name)}>
                                                                {
                                                                    userLoading ?
                                                                        <Loader width={30} height={15}
                                                                                color={pack.color}/> :
                                                                        (
                                                                            userData && userData.subscription ?
                                                                                (
                                                                                    userData.subscription.package === pack.name && userData.subscription.period === currentPeriod ?
                                                                                        <span
                                                                                            className='text'>{t("landing_page.pricing_section.currentPlan")}</span> :
                                                                                            <span className='text'>{t("landing_page.pricing_section.choosePlan")}</span>
                                                                                ) :
                                                                                <span
                                                                                    className='text'>{t("landing_page.pricing_section.getStarted")}</span>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="included-items">
                                                            {
                                                                pack.localizedContent[i18n.language]?.features.map(feature => {
                                                                    return (
                                                                        <div className="included-item">
                                                                            <div className="icon"><MdCheck/></div>
                                                                            {feature}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    canViewFeatureComparison ?
                                        <></> :
                                        <div className="filled-btn button gradient" onClick={() => navigate('pricing')}>
                                            {t("landing_page.pricing_section.view_more_button")} <MdEast/>
                                        </div>
                                }
                            </div>
                    }
                    {
                        canViewFeatureComparison ?
                            <div className='feature-comparison'>
                                <div className="tag-line">
                                    All you need to take your trading to the next level
                                </div>
                                <div className="table">
                                    <div className="header">
                                        <div className="item">
                                            <div className="heading">
                                                {t("landing_page.pricing_section.feature_comparison.heading")}
                                            </div>
                                        </div>
                                        <div className="group">
                                            {
                                                packages.map((pack) => {
                                                    return (
                                                        <div
                                                            className={`item ${pack.packageClass}`}>{pack.name}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="table-body">
                                        <div className="group mobile-row">
                                            <div className="row">
                                                <div className="item"></div>
                                                {
                                                    packages.map((pack) => {
                                                        return (
                                                            <div
                                                                className={`item ${pack.packageClass}`}>{pack.name}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            featureComparison.map((group, index) => {
                                                return (
                                                    <div className="group" key={index}>
                                                        {
                                                            group.name ?
                                                                <div className="group-header">
                                                                    {group.name}
                                                                </div> : <></>
                                                        }
                                                        {
                                                            group.features.map((feature, featureIndex) => {
                                                                return (
                                                                    <div className="row"
                                                                         key={`${index}-${featureIndex}`}>
                                                                        {
                                                                            feature.map(feature => {
                                                                                return (
                                                                                    <div className="item">
                                                                                        {
                                                                                            typeof feature === 'boolean' ?
                                                                                                (
                                                                                                    feature === true ?
                                                                                                        <div
                                                                                                            className="tick">
                                                                                                            <MdCheck/>
                                                                                                        </div> :
                                                                                                        '-'
                                                                                                ) : feature
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> :
                            <></>
                    }
                </div>
        </div>
    )
}

export default PricingSection