import currencyFormat from "../../../common/format";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "../../Providers/ThemeContext";
import useSubAccount from "../../hooks/useSubAccount";
import {sendGet} from "../../../common/request";
import Urls from "../../../common/links";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getRealizedRMultiple} from "../../../common/methods";
import {useTranslation} from "react-i18next";
import permissions from "../../data/Permissions.json";
import ViewPermission from "../permissions/ViewPermission";

const RiskRewardRatioReport = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const {selectedDate: startDate, endDate} = useSelector(state => state.date);
    const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
    const green = "#29D6A8";
    const red = "#ec787d";
    const subAccount = useSubAccount();
    const [data, setData] = useState({});

    const [tradeDistributionChartOptions, setTradeDistributionChartOptions] = useState({
        chart: {
            type: "bar",
            backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
        },
        title: {
            text: "",
        },
        xAxis: {
            title: {
                text: "",
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            labels: {
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            lineColor: gridColor
        },
        yAxis: {
            title: {
                text: "",
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            gridLineColor: gridColor,
            labels: {
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                },
            }
        },
        series: [
            {
                pointWidth: 30,
                showInLegend: false,
                data: [5, 3, 10],
                color: green,
                negativeColor: red,
                borderColor: 'transparent'
            },
        ],
    })

    const [performanceChartOptions, setPerformanceChartOptions] = useState({
        chart: {
            type: "bar",
            backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
        },
        title: {
            text: "",
        },
        xAxis: {
            title: {
                text: "",
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            labels: {
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            lineColor: gridColor
        },
        yAxis: {
            title: {
                text: "",
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                }
            },
            gridLineColor: gridColor,
            labels: {
                style: {
                    color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
                },
                formatter: (data) => {
                    return currencyFormat.format(data.value)
                }
            }
        },
        series: [
            {
                pointWidth: 30,
                showInLegend: false,
                data: [150, 200, -100, 0, -300, 250],
                color: green,
                negativeColor: red,
                borderColor: 'transparent'
            },
        ],
    })

    const findMatchingLimit = (rMultiple, limits) => {
        for(let i = 0; i < limits.length; i += 1) {
            const limit = limits[i];
            if(limit.max === undefined && rMultiple >= limit.min) {
                return i;
            }
            else if (limit.min === undefined && rMultiple <= limit.max) {
                return i;
            }
            else if (rMultiple <= limit.max && rMultiple >= limit.min) {
                return i;
            }
        }
        return -1;
    }

    const fetchData = useCallback(async () => {
        if(subAccount) {

            const limits = [
                {min: 4},
                {max: 3.99, min: 3},
                {max: 2.99, min: 2},
                {max: 1.99, min: 1},
                {max: 0.99, min: 0},
                {max: -0.01, min: -0.99},
                {max: -1, min: -1.99},
                {max: -2, min: -2.99},
                {max: -3, min: -3.99},
                {max: -4},
            ]
            const newData = {
                'None': {
                    title: "None",
                    tradesCount: 0,
                    pnl: 0,
                    winningTrades: 0,
                    losingTrades: 0,
                    profits: 0,
                    losses: 0,
                    volume: 0,
                }
            }
            limits.forEach((limit, index) => {
                newData[`limit-${index}`] = {
                    title: limit.max === undefined ? `${limit.min}R and more` : (limit.min === undefined ? `${limit.max}R or less` : `${limit.max}R to ${limit.min}R`),
                    tradesCount: 0,
                    pnl: 0,
                    winningTrades: 0,
                    losingTrades: 0,
                    profits: 0,
                    losses: 0,
                    volume: 0,
                };
            })

            const response = await sendGet(Urls.SearchTrades(subAccount.id, {startDate, endDate}), true);
            if(response.error) {
                toast.error(response.error);
            }
            else if(response.trades && response.trades.length > 0) {
                for(const trade of response.trades) {

                    const rMultiple = getRealizedRMultiple(trade.data);
                    const limitIndex = findMatchingLimit(rMultiple, limits);
                    let limit = '';
                    if(limitIndex === -1)
                        limit = 'None';
                    else
                        limit = `limit-${limitIndex}`;

                    newData[limit].tradesCount += 1;
                    newData[limit].pnl += trade.data.PnL;
                    newData[limit].volume += Math.abs(trade.data.CloseVolume)

                    if(trade.data.PnL > 0) {
                        newData[limit].winningTrades += 1;
                        newData[limit].profits += trade.data.PnL;
                    }
                    else if (trade.data.PnL < 0) {
                        newData[limit].losingTrades += 1;
                        newData[limit].losses += trade.data.PnL;
                    }

                }

                setData(newData);
                setTradeDistributionChartOptions({
                    ...tradeDistributionChartOptions,
                    xAxis: {
                        categories: Object.values(newData).map(item => item.title),
                        ...tradeDistributionChartOptions.xAxis
                    },
                    series: [
                        {
                            pointWidth: 20,
                            showInLegend: false,
                            data: Object.values(newData).map(value => value.tradesCount),
                            color: '#2d90cc',
                            negativeColor: red,
                            borderColor: 'transparent'
                        },
                    ],
                })
                setPerformanceChartOptions({
                    ...performanceChartOptions,
                    xAxis: {
                        categories: Object.values(newData).map(item => item.title),
                        ...performanceChartOptions.xAxis
                    },
                    series: [
                        {
                            pointWidth: 20,
                            showInLegend: false,
                            data: Object.values(newData).map(value => value.pnl),
                            color: green,
                            negativeColor: red,
                            borderColor: 'transparent'
                        },
                    ],
                })
            }
            else {
                setData(newData);
                setTradeDistributionChartOptions({
                    ...tradeDistributionChartOptions,
                    xAxis: {
                        categories: Object.values(newData).map(item => item.title),
                        ...tradeDistributionChartOptions.xAxis
                    },
                    series: [
                        {
                            pointWidth: 20,
                            showInLegend: false,
                            data: Object.values(newData).map(value => value.tradesCount),
                            color: '#2d90cc',
                            negativeColor: red,
                            borderColor: 'transparent'
                        },
                    ],
                })
                setPerformanceChartOptions({
                    ...performanceChartOptions,
                    xAxis: {
                        categories: Object.values(newData).map(item => item.title),
                        ...performanceChartOptions.xAxis
                    },
                    series: [
                        {
                            pointWidth: 20,
                            showInLegend: false,
                            data: Object.values(newData).map(value => value.pnl),
                            color: green,
                            negativeColor: red,
                            borderColor: 'transparent'
                        },
                    ],
                })
            }
        }
    }, [subAccount])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getClass = (value) => {
        return value < 0 ? 'negative' : (value > 0 ? 'positive' : '')
    }

    return (
        <ViewPermission permission={permissions.PAGE.REPORTS.RISK_REWARD}>
            <div className={`report-data-page risk-reward-ratio-report ${theme.getThemeClass()}`}>
                <div className="content">
                    <div className="data">
                        <div className="chart-container">
                            <div className="heading">
                                {t("reports.risk_reward_ratio_report.trade_distribution_chart.heading")}<br/><span
                                className="tag-line">({t("reports.all_dates")})</span>
                            </div>
                            <div className="chart auto">
                                <HighchartsReact
                                    containerProps={{style: {width: "100%"}}}
                                    highcharts={Highcharts}
                                    options={tradeDistributionChartOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="data">
                        <div className="chart-container">
                            <div className="heading">
                                {t("reports.risk_reward_ratio_report.performance_chart.heading")}<br/><span
                                className="tag-line">({t("reports.all_dates")})</span>
                            </div>
                            <div className="chart auto">
                                <HighchartsReact
                                    containerProps={{style: {width: "100%"}}}
                                    highcharts={Highcharts}
                                    options={performanceChartOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <div className="heading">{t("reports.risk_reward_ratio_report.summary")}</div>
                    <div className="table">
                        <div className="header">
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.0")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.1")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.2")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.3")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.4")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.5")}</div>
                            <div className="table-item">{t("reports.risk_reward_ratio_report.headers.6")}</div>
                        </div>
                        {
                            Object.keys(data).map((limit, index) => {
                                const limitData = data[limit];
                                const winningPercentage = (limitData.winningTrades / limitData.tradesCount) * 100;
                                const losingPercentage = (limitData.losingTrades / limitData.tradesCount) * 100;
                                return (
                                    <div className="row" key={index}>
                                        <div className="table-item">{limitData.title}</div>
                                        <div
                                            className={`table-item ${getClass(limitData.pnl)}`}>{currencyFormat.format(limitData.pnl)}</div>
                                        <div className="table-item">
                                            <div className="bars">
                                                <div className="negative-bar">
                                                    <div className="fill-bar"
                                                         style={{width: `${losingPercentage}%`}}></div>
                                                </div>
                                                <div className="positive-bar">
                                                    <div className="fill-bar"
                                                         style={{width: `${winningPercentage}%`}}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`table-item ${getClass(limitData.profits)}`}>{currencyFormat.format(limitData.profits)}</div>
                                        <div
                                            className={`table-item ${getClass(limitData.losses)}`}>{currencyFormat.format(limitData.losses)}</div>
                                        <div className="table-item">{limitData.tradesCount}</div>
                                        <div className="table-item">{limitData.volume}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </ViewPermission>
    )
}

export default RiskRewardRatioReport;