import {MdCheck, MdClose, MdPriorityHigh} from "react-icons/md";
import "../../styles/tags-modal.scss";

const MessageModal = ({
    isOpen,
    handleCloseModal,
    heading,
    message,
    mode
}) => {

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal dark message-modal`}>
            <div className="modal-header">
              <div className="title">
                Message
              </div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
              <div className="modal-body">
                  <div className="insights-container">
                      <div className={`insights-icon ${mode}`}>
                          {
                              mode === 'success' ?
                                  <MdCheck /> :
                                  <MdPriorityHigh />
                          }
                      </div>
                      <div className="insights-message">
                          <div className="header">
                              {heading}
                          </div>
                          <div className="message">
                              {message}
                          </div>
                      </div>
                  </div>
              </div>
              {/*<div className="modal-footer">*/}
              {/*    <button className="secondary-btn" onClick={handleCloseModal}>*/}
              {/*      Ok*/}
              {/*    </button>*/}
              {/*</div>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageModal;
