import React, {useEffect, useRef, useState} from "react";
import SideBar from "./assets/components/sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./common/i18n";
import links, {getSocketLink} from "./common/links";
import Header from "./assets/components/Header.jsx";
import { MdChevronLeft } from "react-icons/md";
import {sendGet} from "./common/request";
import {setUnReadMessages} from "./redux/community/action";
import {io} from "socket.io-client";
import {getToken} from "./common/token";
import SocketContext from "./assets/contexts/SocketContext";
import {useTheme} from "./assets/Providers/ThemeContext";
import NoSubscriptionDisplay from "./assets/components/NoSubscriptionDisplay";
import Modal from "./assets/components/Modal/Modal";
import {setPermissionErrorDisplay} from "./redux/permission/action";
import DailyInsights from "./assets/components/DailyInsights";

const Root = () => {
  const dispatch = useDispatch();
  const unReadMessages = useSelector(state => state.community.unReadMessages);
  const [loading, setLoading] = useState(false);
  const {user} = useSelector(state => state.user)
  const {shown} = useSelector(state => state.permissionModal);
  let navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const location = useLocation();
  const [socket, setSocket] = useState({});
  const socketRef = useRef();
  const unReadMessagesRef = useRef(0);
  let token = getToken();
  const themeContext = useTheme();

  async function fetchUnReadMessagesCount() {
    const response = await sendGet(links.GetAllUnreadMessagesCount, true);
    if(response.error) {
      console.log(response.error);
    } else {
      dispatch(setUnReadMessages(response.unReadMessagesCount));
    }
  }

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    fetchUnReadMessagesCount();
  }, [dispatch, navigate]);

  useEffect(() => {
        setTimeout(() => {
          const sidebarExpandButtons = document.querySelectorAll(".sidebar-expand-btn");
          if(sidebarExpandButtons.length > 0) {
            for(let sidebarExpandBtn of sidebarExpandButtons) {
              sidebarExpandBtn.addEventListener("click", () => {
                  document.querySelector(".sidebar").classList.add("expanded");
              })
            }
          }
        }, 500)
    }, [location]);

  useEffect(() => {
    if(!socketRef.current) {
      const sLink = io(getSocketLink(), {
        auth: {
          token: getToken()
        },
      });
      socketRef.current = sLink;

      sLink.on('connect', () => {
        console.log('Socket Connection Successful');
        sLink.on('onChatMessageReceived', (data) => {
          unReadMessagesRef.current += 1;
          dispatch(setUnReadMessages(unReadMessagesRef.current));
        })
      })

      setSocket({socket: sLink});
    }
  }, []);

  useEffect(() => {
    unReadMessagesRef.current = unReadMessages;
  }, [unReadMessages]);

  return (
      <SocketContext.Provider value={socket}>
        <DailyInsights />
        <div className={`App ${isSidebarCollapsed ? 'collapsed' : ''} ${themeContext.getThemeClass()}`}>
          <div className={`sidebar`} id="sidebar">
            <div className="sidebar-toggle-btn" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
              <MdChevronLeft/>
            </div>
            <SideBar/>
          </div>
          <div className="page-container">
            <div className="page">
              {
                loading ? <></> :
                  user && user.user && user.user.subscription && !user.user.subscription.expired ?
                    <>
                      <Header/>
                      <Outlet/>
                    </> :
                    <NoSubscriptionDisplay />
              }
            </div>
          </div>
          <Modal title='Cannot Perform this action' isShown={shown} className={'modal-md permission-error-modal'} onClose={() => dispatch(setPermissionErrorDisplay(false))}>
            <Modal.Body>
              <div className="message">
                This action is not allowed in the current package. Please <strong>upgrade</strong> your package or <strong>go back</strong>.
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="primary-btn" onClick={() => {
                dispatch(setPermissionErrorDisplay(false));
                navigate('/pricing')
              }}>
                Change Plan
              </div>
              <div className="secondary-btn" onClick={() => dispatch(setPermissionErrorDisplay(false))}>
                Go Back
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </SocketContext.Provider>
  );
};

export default Root;
