import CTABackground from '../../images/cta-background.png';
import {MdEast} from "react-icons/md";
import {useTranslation} from "react-i18next";

const CTASection = () => {
    const {t} = useTranslation();
    return (
        <div className="cta-section front">
            <img src={CTABackground} alt="CTABackground" className='background' />
            <div className="content">
                <div className="title">
                    {t("landing_page.cta.title.before")} <span className='primary'>{t("landing_page.cta.title.gradient1")}</span> <span className='secondary'>{t("landing_page.cta.title.gradient2")}</span> {t("landing_page.cta.title.after")}
                </div>
                <div className="description">{t("landing_page.cta.description")}</div>
                <div className="button filled-btn gradient">
                    {t("landing_page.cta.register_button")} <MdEast />
                </div>
            </div>
        </div>
    )
}

export default CTASection;