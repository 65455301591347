const initialState = {
  packages: [],
  loading: false,
  error: null,
  language: 'EN'
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PACKAGES":
      return {...state, packages: [], loading: true, error: null}
    case "PACKAGES_SUCCESSFUL":
      return { ...state, packages: action.payload, loading: false, error: null };
    case "PACKAGES_FAILURE":
      return {...state, packages: [], loading: false, error: action.payload}
    case 'CHANGE_LANGUAGE':
      return {...state, language: action.payload}
    default:
      return state;
  }
};

export default dataReducer;
