import "../styles/sidebar.scss";
import SideBarButton from "./sidebar-button";
import logo from "../images/logo/logo-light.png";
import logoDark from "../images/logo/logo-dark.png";
import {
  MdDashboard,
  MdAnalytics,
  MdBook,
  MdLogout,
  MdDescription,
  MdOutlineMenuBook,
  MdPeopleAlt,
  MdClose,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteToken } from "../../common/token";
import { useSelector } from "react-redux";
import ImagePlaceholder from "../images/image-placeholder.jpg";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";
import {useContext} from "react";
import SocketContext from "../contexts/SocketContext";

function SideBar() {
  let location = useLocation();
  const accounts = useSelector((state) => state?.user?.user);
  const community = useSelector(state => state.community);
  let navigation = useNavigate();
  let { t } = useTranslation();
  const theme = useTheme();
  const socketContext = useContext(SocketContext);

  let logout = () => {
    deleteToken();
    if(socketContext.socket) {
      socketContext.socket.disconnect();
    }
    navigation("/login");
  };

  const collapseSidebar = () => {
    document.querySelector(".sidebar").classList.remove("expanded");
  };

  return (
    <div className={`sidebar-container ${theme.getThemeClass()}`}>
      <div>
        <div className="header">
          <img src={theme.theme === 'dark' ? logoDark : logo} className="logo" alt="Logo" />
          <div className="sidebar-collapse-btn" onClick={collapseSidebar}>
            <MdClose />
          </div>
        </div>
        <div className="nav-btns">
          <SideBarButton
            title={t("dashboard_page")}
            active={location.pathname === "/dashboard"}
            icon={MdDashboard}
            href={"/dashboard"}
          />
          <SideBarButton
            title={t("daily_journal_page")}
            active={location.pathname.startsWith("/daily-journal")}
            icon={MdBook}
            href={"/daily-journal"}
          />
          <SideBarButton
            title={t("reports_page")}
            active={location.pathname.startsWith("/reports")}
            icon={MdAnalytics}
            href={"/reports"}
          />
          <SideBarButton
            title={t("notebook_page")}
            active={location.pathname.startsWith("/notebook")}
            icon={MdOutlineMenuBook}
            href={"/notebook"}
          />
          <SideBarButton
            title={t("playbook_page")}
            active={location.pathname.startsWith("/playbook")}
            icon={MdDescription}
            href={"/playbook"}
          />
          <SideBarButton
            title={t("community_page")}
            active={location.pathname.startsWith("/community")}
            icon={MdPeopleAlt}
            href={"/community"}
            count={community.unReadMessages}
          />
          <SideBarButton
            title={t("logout_action")}
            type="danger"
            icon={MdLogout}
            click={logout}
          />
        </div>
      </div>
      <div className="user-container">
        <div className="user" onClick={() => navigation("/settings/profile")}>
          <div className="profile-img">
            {accounts?.user && accounts.user.profileImage ? (
              <img src={accounts.user.profileImage} alt="profile" />
            ) : (
              <img src={ImagePlaceholder} alt="profile placeholder" />
            )}
          </div>
          <div className="detail">
            <div className="name">
              {accounts?.user?.firstName} {accounts?.user?.lastName}
            </div>
            <div className="email">{accounts?.user?.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
