import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import links from "../../../common/links";
import { sendGet } from "../../../common/request";
import { useTranslation } from "react-i18next";
import currencyFormat from "../../../common/format";
import {useTheme} from "../../Providers/ThemeContext";
import useSubAccount from "../../hooks/useSubAccount";
import {toast} from "react-toastify";
import permissions from "../../data/Permissions.json";
import ViewPermission from "../permissions/ViewPermission";

const SetupsReport = () => {

  const {t} = useTranslation()
  const green = "#29D6A8";
  const red = "#ec787d";
  const blue = '#2d90cc'
  const theme = useTheme();
  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  const [setups, setSetups] = useState({});
  const subAccount = useSubAccount();
  const {selectedDate: startDate, endDate} = useSelector(state => state.date);

  const loadTrades = async () => {
    const d = new Date(startDate);
    const response = await sendGet(links.SearchTrades(subAccount.id, {startDate, endDate: endDate ? endDate : new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)}), true);
    if(response.error) {
      toast.error(response.error);
    }
    else {
      const trades = response.trades;
      const setupData = {};
      for(const trade of trades) {
        if(trade.playbooks && trade.playbooks.length > 0) {
          const playbook = trade.playbooks[0];
          if(!setupData[playbook.name]) {
            setupData[playbook.name] = {
              name: playbook.name,
              count: 0,
              pnl: 0,
              profit: 0,
              loss: 0,
              winningTrades: 0,
              volume: 0
            }
          }

          setupData[playbook.name].count += 1;
          setupData[playbook.name].volume += Math.abs(trade.data.OpenVolume);
          setupData[playbook.name].pnl += trade.data.PnL;
          if(trade.data.PnL > 0) {
            setupData[playbook.name].profit += trade.data.PnL;
            setupData[playbook.name].winningTrades += 1;
          }
          else if(trade.data.PnL < 0) {
            setupData[playbook.name].loss += trade.data.PnL;
          }
        }
      }
      setSetups(setupData)
    }
  }

  useEffect(() => {
    if(subAccount) {
      loadTrades()
    }
  }, [subAccount, startDate, endDate])

  const barSecondHighChartOptionsSetup = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: Object.keys(setups),
      title: {
        text: "SetupsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Accumulated PnL",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        },
        formatter: (data) => {
          return currencyFormat.format(data.value)
        }
      }
    },
    series: [
      {
        pointWidth: 30,
        showInLegend: false,
        data: Object.values(setups).map(item => item.pnl),
        color: green,
        negativeColor: red,
        borderColor: "transparent"
      },
    ],
  };

  const barHighChartOptionsSetup = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: Object.keys(setups),
      title: {
        text: "SetupsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Number of Trades",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      }
    },
    series: [
      {
        pointWidth: 30,
        showInLegend: false,
        data: Object.values(setups).map(item => item.count),
        color: blue,
        borderColor: "transparent"
      },
    ],
  };

  return (
      <ViewPermission permission={permissions.PAGE.REPORTS.SETUPS}>
        <div className="w-full flex flex-col items-center pt-4">
          <div className="items-center w-11/12 justify-center m-0 sm:m-2 md:m-4 p-0">
            <div className="flex mb-4 w-full items-center">
              <div
                  className="flex flex-col mt-1 w-full md:flex-col sm:flex-col xs:flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-4">
                <div
                    className={`mb-4 md:mb-0 w-[100%] rounded-md p-4 ${theme.theme === 'dark' ? 'bg-transparent' : 'bg-white'}`}
                    style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
                  <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {t("setups_report_distribution_heading")} <br/> ({t("reports.all_trades")})
                  </h3>
                  <div className="high-chart">
                    <HighchartsReact
                        containerProps={{style: {width: "100%"}}}
                        highcharts={Highcharts}
                        options={barHighChartOptionsSetup}
                    />
                  </div>
                </div>
                <div
                    className={`mb-4 md:mb-0 w-[100%] md:mr-2 rounded-md p-4 ${theme.theme === 'dark' ? 'bg-transparent' : 'bg-white'}`}
                    style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
                  <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {t("setups_report_performance_heading")} <br/> ({t("reports.setups_report.all_setups")})
                  </h3>
                  <div className="high-chart">
                    <HighchartsReact
                        containerProps={{style: {width: "100%"}}}
                        highcharts={Highcharts}
                        options={barSecondHighChartOptionsSetup}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
                className={`rounded-md p-4 gap-4 w-full overflow-hidden ${theme.theme === 'dark' ? 'transparent' : 'bg-white'}`}
                style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
              <h1 className={`text-lg font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-800'}`}>
                {t("setups_report_summary_heading")}
              </h1>
              <hr className={`border-t my-2 ${theme.theme === 'dark' ? 'border-gray-500' : 'border-gray-200'}`}/>
              <div className="rounded-sm p-5 mt-5 overflow-hidden overflow-x-auto w-full">
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: "bold",
                      minWidth: "1000px"
                    }}
                    className={`bg-primary-base border-b ${theme.theme === 'dark' ? 'border-b-gray-500' : 'border-b-gray-200'}`}>
                  <div className="flex-1">{t("setups_report_label_setup")}</div>
                  <div className="flex-1">{t("setups_report_label_net_profit")}</div>
                  <div className="flex-1">{t("setups_report_label_winning")}</div>
                  <div className="flex-1">{t("setups_report_label_total_profit")}</div>
                  <div className="flex-1">{t("setups_report_label_total_loss")}</div>
                  <div className="flex-1">{t("setups_report_label_trades")}</div>
                  <div className="flex-1">{t("setups_report_label_volume")}</div>
                </div>
                {Object.values(setups).map((setup, index) => (
                    <div key={index}
                         className={`flex items-center justify-center border-b ${theme.theme === 'dark' ? 'border-b-gray-500 text-gray-100' : 'border-b-gray-200 text-gray-700'} ${theme.theme === 'dark' ? (index % 2 === 0 ? "bg-gray-600" : "bg-gray-650") : (index % 2 === 0 ? "bg-white" : "bg-gray-75")}`}
                         style={{
                           display: "flex",
                           padding: '10px',
                           minWidth: "1000px",
                           cursor: 'pointer',
                         }}>
                      <div className="flex-1">{setup.name}</div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (setup?.pnl >= 0 ? "text-green-200" : "text-red-200") : (setup?.pnl >= 0 ? "text-green-400" : "text-red-400")} font-bold`}>
                        {currencyFormat.format(setup?.pnl)}
                      </div>
                      <div className="flex-1">{((setup.winningTrades / setup.count) * 100).toFixed(2)}%</div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (setup.profit > 0 ? "text-green-200" : "text-gray-100") : (setup.profit > 0 ? "text-green-400" : "text-black")}`}>
                        {currencyFormat.format(setup?.profit)}
                      </div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (setup.loss >= 0 ? "text-gray-100" : "text-red-200") : (setup.loss >= 0 ? "text-black" : "text-red-400")}`}>
                        {currencyFormat.format(setup?.loss)}
                      </div>
                      <div className="flex-1">{setup.count}</div>
                      <div className="flex-1">{setup.volume.toFixed(2)}</div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ViewPermission>
  );
};

export default SetupsReport;
