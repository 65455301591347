import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {sendPost} from "../../common/request";
import Urls from '../../common/links';
import "../styles/payment-screen.scss";
import {useParams} from "react-router-dom";
import Loader from "../components/Loader";
import {useSelector} from "react-redux";
import currencyFormat from "../../common/format";

const stripePromise = loadStripe("pk_test_51LclBYJ91W15khFNocy5OikmAptfxp4rVQTs7iJEYoRMQINlcdLTTki3oDYWHZnhwHGv2eWXYVyhkzLAEcfk854600P1Ys3CoJ");

const CheckoutForm = ({price, period, paymentIntent, packageName, purpose}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();

        if(!stripe || !elements) {
            return;
        }

        setLoading(true)
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/confirm?paymentIntent=${paymentIntent}&packageName=${packageName}&period=${period}&purpose=${purpose}`
            }
        });
        setLoading(false)
        if(result.error) {
            console.log(result.error);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <PaymentElement/>
            <button disabled={!stripe} className={`${loading ? 'no-hover' : ""}`}>
                {
                    loading ?
                        <Loader height={20} width={30} /> :
                        'Submit'
                }
            </button>
        </form>
    )
}

const PaymentPage = () => {

    const [options, setOptions] = useState({
        appearance: {
            theme: 'night',
            labels: 'floating',
            variables: {
                colorPrimary: '#00ffb3'
            }
        }
    });
    const {packageName, period, purpose} = useParams();
    const [price, setPrice] = useState(0);
    const [paymentIntent, setPaymentIntent] = useState(undefined);
    const loading = useRef(false);
    const {user} = useSelector(state => state.user);
    const {packages} = useSelector(state => state.data);
    const [currentPackage, setCurrentPackage] = useState({});

    const createPaymentIntent = useCallback(async (packages, user) => {
        if(loading.current) {
            return;
        }

        loading.current = true;
        const packageDetails = packages.filter(pack => pack.name === packageName)[0];

        if(packageDetails) {
            setCurrentPackage(packageDetails)
            let price = packageDetails.price[period];
            if(user && user.subscription && user.subscription.expired === false) {
                const previousPackage = packages.filter(pack => pack.name === user.subscription.package)[0];
                if(previousPackage) {
                    price -= previousPackage.price[user.subscription.period];
                }
            }
            else if (user && !user.subscription && packageDetails.discount && packageDetails.discount[period]) {
                price = (packageDetails.price['month'] - ((packageDetails.discount[period]?.firstYear * packageDetails.price['month']) / 100)) * 12;
            }

            setPrice(price)
            const sessionResponse = await sendPost(Urls.CreatePaymentIntent, {
                price: price * 100,
            }, true, 'application/json', true);
            loading.current = false;
            setOptions({
                ...options,
                clientSecret: sessionResponse.client_secret,
            });
            console.log(sessionResponse.paymentIntent)
            setPaymentIntent(sessionResponse.paymentIntent)
        }
    }, [])

    useEffect(() => {
        if(user && user.user && packages.length > 0) {
            createPaymentIntent(packages, user.user);
        }
    }, [createPaymentIntent, user, packages]);

    return (
        <div className='payment-screen'>
            <div className="payment-form">
                {
                    options.clientSecret ?
                        <>
                            <div className="data-card">
                                <div className="header">Subscription Details</div>
                                <div className="rows">
                                    <div className="data-row">
                                        <div className="header">Package Name</div>
                                        <div className="value">{currentPackage.name}</div>
                                    </div>
                                    <div className="data-row">
                                        <div className="header">Period</div>
                                        <div className="value">{period === 'year' ? 'Yearly' : 'Monthly'}</div>
                                    </div>
                                    {
                                        currentPackage.discount && currentPackage.discount[period] && user && user.user && !user.user.subscription ?
                                            <>
                                                <div className="data-row">
                                                    <div
                                                        className="header">First {period[0].toUpperCase()}{period.slice(1)} Price
                                                    </div>
                                                    <div
                                                        className="value">{currencyFormat.format(price)}</div>
                                                </div>
                                                <div className="data-row">
                                                    <div className="header">After First Year</div>
                                                    <div
                                                        className="value">{currencyFormat.format(currentPackage.price[period])}</div>
                                                </div>
                                            </> :
                                            <div className="data-row">
                                                <div className="header">Price</div>
                                                <div className="value">{currencyFormat.format(price)}</div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm price={price} packageName={packageName} period={period}
                                              paymentIntent={paymentIntent} purpose={purpose}/>
                            </Elements>
                        </> :
                        <Loader/>
                }
            </div>
        </div>
    )
}

export default PaymentPage;