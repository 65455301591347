import {useEffect, useRef} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {sendPost} from "../../common/request";
import Urls from "../../common/links";
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../redux/userActions.js/action";

const ConfirmationPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const loading = useRef(false);
    const {packages} = useSelector(state => state.data);
    const dispatch = useDispatch();

    const confirmPayment = async () => {

        if(loading.current) {
            return;
        }

        loading.current = true;

        try {
            const paymentIntentId = searchParams.get('paymentIntent')
            const packageName = searchParams.get('packageName')
            const period = searchParams.get('period')
            const purpose = searchParams.get('purpose')
            if(paymentIntentId && packageName && period && purpose) {

                const packageData = packages.filter(pack => pack.name === packageName)[0];

                const response = await sendPost(Urls.ConfirmPaymentIntent(paymentIntentId), {
                    period: period,
                    packageName: packageName,
                    purpose: purpose,
                    permissions: packageData.permissions
                }, true, 'application/json', true);
                if(response.subscription && response.user) {
                    dispatch(setUser({
                        user: response.user,
                    }))
                    navigate('/dashboard');
                }
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            loading.current = false;
        }
    }

    useEffect(() => {
        if(packages.length > 0) {
            confirmPayment();
        }
    }, [packages]);

    return (
        <div className='confirmation-page'>
            <Loader fullScreen={true} text={'Hang Tight! We are confirm your subscription. Please do not close this window'} />
        </div>
    )
}

export default ConfirmationPage