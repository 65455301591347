const initialState = {
  user: null,
  insightsShown: false,
  insightsSubAccount: -1,
  loading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCHING_USER":
      return {...state, user: null, loading: true, error: null}
    case "SET_USER":
      return { ...state, user: action.payload, loading: false, error: null };
    case "SET_ERROR":
      return {...state, user: null, loading: false, error: action.payload}
    case "SET_INSIGHTS":
      return {...state, insightsShown: action.payload.insightsShown, insightsSubAccount: action.payload.insightsSubAccount };
    default:
      return state;
  }
};

export default userReducer;
