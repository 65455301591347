import { useEffect, useState } from "react";
import {
  MdAdd,
  MdCalendarMonth,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import links from "../../common/links";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import createHeader from  '../../redux/header/createHeader';
import NoResultImage from "../images/no-result.png"
import {useTheme} from "../Providers/ThemeContext";
import permissions from "../data/Permissions.json"
import ActionPermission from "../components/permissions/ActionPermission";

function DailyJournal() {
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const dispatch = useDispatch();
  const selectedAccount = useSelector(
    (state) => state.accounts.selectedAccounts
  );

  const accounts = useSelector((state) => state?.user?.user);
  let navigation = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [totalPnl, setTotalPnl] = useState(0);
  const [positivePnl, setPositivePnl] = useState(0);
  const [negativePnl, setNegativePnl] = useState(0);
  const [numberOfPositiveDays, setNumberOfPositiveDays] = useState(0);
  const [numberOfNegativeDays, setNumberOfNegativeDays] = useState(0);
  const [dailyProfit, setDailyProfit] = useState([]);
  const [dailyLoss, setDailyLoss] = useState([]);
  const theme = useTheme();

  const handleDecrement = () => {

    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
    sessionStorage.setItem("currentDate", newDate);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIncrement = () => {

    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
    sessionStorage.setItem("currentDate", newDate);
  };
  const subUserId = accounts?.user?.subAccounts?.filter(
    (account) => account?.name === selectedAccount
  )[0]?.id
    ? accounts?.user?.subAccounts?.filter(
        (account) => account?.name === selectedAccount
      )[0]?.id
    : accounts?.user?.subAccounts?.[0]?.id;

  useEffect(() => {
    async function fetchSubAccounts() {
      try {
        let token = localStorage.getItem("token");
        const response = await fetch(links.GetTrades(subUserId), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        // Filter data for the specific subUserId
        const positiveDays = data.reduce((days, item) => {
          const closeDate = new Date(item.data.CloseTime);
          const NewSelecteddate = new Date(selectedDate);
          const NewEndDate = new Date(endDate);

          if (closeDate >= NewSelecteddate && closeDate <= NewEndDate) {
            const pnl = parseFloat(item.data.PnL);
            if (!isNaN(pnl) && pnl > 0) {
              days.add(closeDate.toLocaleDateString());
            }
          }

          return days;
        }, new Set());

        const negativeDays = data.reduce((days, item) => {
          const closeDate = new Date(item.data.CloseTime);
          const NewSelecteddate = new Date(selectedDate);
          const NewEndDate = new Date(endDate);

          if (closeDate >= NewSelecteddate && closeDate <= NewEndDate) {
            const pnl = parseFloat(item.data.PnL);
            if (!isNaN(pnl) && pnl < 0) {
              days.add(closeDate.toLocaleDateString());
            }
          }

          return days;
        }, new Set());

        let dailyProfitObj = {};
        let dailyLossObj = {};

        // Step 2: Calculate daily profit and loss
        data.forEach((item) => {
          const closeDate = new Date(item.data.CloseTime).toLocaleDateString();
          const pnl = parseFloat(item.data.PnL);
          if (!isNaN(pnl)) {
            if (pnl > 0) {
              // Positive PnL
              dailyProfitObj[closeDate] =
                (dailyProfitObj[closeDate] || 0) + pnl;
            } else if (pnl < 0) {
              // Negative PnL
              dailyLossObj[closeDate] = (dailyLossObj[closeDate] || 0) + pnl;
            }
          }
        });

        if (data) {
          setData(data);
          let positivePnl = 0;
          let negativePnl = 0;

          const totalPnls = data.reduce((total, item) => {
            const openDates = new Date(item?.data?.OpenTime);

            const NewSelecteddate = new Date(selectedDate);
            const NewEndDate = new Date(endDate);

            if (
              item?.data &&
              item?.data?.PnL !== undefined &&
              openDates >= NewSelecteddate &&
              openDates <= NewEndDate
            ) {
              const pnl = parseFloat(item?.data?.PnL);
              // Check if pnl is a number before adding to total
              if (!isNaN(pnl)) {
                if (pnl > 0) {
                  positivePnl += pnl;
                } else {
                  negativePnl += pnl;
                }
                return total + pnl;
              } else {
                console.error("Invalid PnL value:", pnl);
                return total;
              }
            } else {
              console.error("PnL is not available:", item.data);
              return total;
            }
          }, 0);

          const dailyProfitArray = Object.entries(dailyProfitObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          const dailyLossArray = Object.entries(dailyLossObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          const numberOfPositiveDays = positiveDays.size;
          setNumberOfPositiveDays(numberOfPositiveDays);
          setNumberOfNegativeDays(negativeDays.size);

          setPositivePnl(positivePnl);
          setNegativePnl(negativePnl);
          setTotalPnl(totalPnls);
          setDailyProfit(dailyProfitArray);
          setDailyLoss(dailyLossArray);
        } else {
          // If no data is found for the subUserId, set totalPnl to 0
          setTotalPnl(0);
          setPositivePnl(0);
          setNegativePnl(0);
          setNumberOfPositiveDays(0);
          setNumberOfNegativeDays(0);
          setDailyProfit([]);
          setDailyLoss([]);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching sub accounts:", error);
      }
    }

    fetchSubAccounts();

    // get the current date from session storage
    const currentDate = sessionStorage.getItem("currentDate");
    if (currentDate) {
      setCurrentDate(new Date(currentDate));
    }
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  const subAccountsNames =
    accounts?.user?.subAccounts?.map((account) => account.name) || [];

  const filteredData = data?.sort((a, b) => {
    return new Date(a?.data?.OpenTime) - new Date(b?.data?.OpenTime);
  })

  const groupDataByDate = (data) => {

    const groupedData = {};
    data.forEach((e) => {
      let openTime = new Date(e?.data?.OpenTime);
      if (openTime.getMonth() === currentDate.getMonth() && openTime.getFullYear() == currentDate.getFullYear()) {
        const dateKey = new Date(e?.data?.OpenTime).toDateString();
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = [];
        }
        groupedData[dateKey].push(e);
      } else {
        console.log("not equal");
      }
    });
    console.log(groupedData);
    return groupedData;
  };
  let { t } = useTranslation();
  dispatch(createHeader(t("daily_journal_page"), true, false))
  let getMonth = (date) => {
    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return months[date.getMonth()];
  };

  let getDay = (date) => {
    let days = [
      t("day_sunday"),
      t("day_monday"),
      t("day_tuesday"),
      t("day_wednesday"),
      t("day_thursday"),
      t("day_friday"),
      t("day_saturday"),
    ];
    return days[date.getDay()];
  };

  const renderCard = (date, entries) => {
    const netPnL = parseFloat(entries.reduce(
        (total, e) => total + parseFloat(e?.data?.PnL),
        0
    ).toFixed(2));
    let newDate = moment(date).toDate();
    let parsedDate = moment(date);

    return (
      <div key={date} className={`rounded-2xl h-[100%] border shadow-sm hover:shadow-lg transition duration-300 ease-in-out lg:p-3 xl:p-5 ${theme.theme === 'dark' ? 'border-gray-500 shadow-gray-700 hover:shadow-gray-600' : 'border-gray-100'}`}>
        <div className="h-[100%] p-3 xl:p-4 flex flex-col justify-between align-center">
          <div className="flex justify-between items-center mb-3">
            <p className={`font-bold text-base ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
              {getDay(newDate)}, {parsedDate.format("DD MMM YYYY")}
            </p>
            <p
              className={`text-base rounded-full flex items-center justify-center font-bold cursor-pointer border-none p-1 ${theme.theme === 'dark' ? 'text-gray-100 bg-gray-500' : 'text-gray-700 bg-gray-300'}`}
              onClick={() =>
                navigation(`/daily-journal/${parsedDate.format("DD-MM-YYYY")}`)
              }
            >
              <MdChevronRight />
            </p>
          </div>
          <div className="grid grid-cols-2 lg:gap-x-[0.75em] xl:gap-x-[1em]">
            <div className="flex flex-col gap-2">
              <p className={`${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {t("daily_journal_net_pnl")}:{" "}
              </p>
              <p className={`${netPnL > 0 ? "text-green-300" : netPnL === 0 ? "text-black" : "text-red-300"}`}
                style={{
                  fontWeight: "800",
                  fontSize: "1rem",
                  // padding: "-1rem",
                }}
              >
                {netPnL > 0
                  ? "$" + netPnL
                  : netPnL < 0
                  ? "-$" + netPnL / -1
                  : netPnL}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className={`${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {t("daily_journal_total_trades")}:
              </p>
              <p className={`${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {entries.length}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const groupedData = groupDataByDate(filteredData);

  return (
    <div className="page-body">
          <div className="flex items-center justify-end sm:justify-between">
            <div className="flex-col sm:flex-row flex w-full gap-4 justify-end items-end">
              <div className="flex items-center justify-center gap-2">
                <button
                    className={`text-base p-2 rounded-full bg-lightgray flex items-center justify-center font-light cursor-pointer border-none ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}
                    onClick={handleDecrement}>
                  <MdChevronLeft/>
                </button>
                <span
                    className={`flex items-center p-2 border-2 border-primary-500 rounded gap-2 w-[200px] font-light justify-between ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}>
              <MdCalendarMonth className="text-primary-500"/>
                  {moment(currentDate).format("MMMM YYYY")}
            </span>
                <button
                    className={`text-base p-2 rounded-full bg-lightgray flex items-center justify-center font-light cursor-pointer border-none ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}
                    onClick={handleIncrement}>
                  <MdChevronRight/>
                </button>
              </div>
              <ActionPermission className="btn btn-primary" onClick={() => navigation("/addtrade")}
                                permission={permissions.PAGE.TRADE_IMPORT}>
                <MdAdd/>
                {t("add_trade_btn")}
              </ActionPermission>
            </div>
          </div>
          <div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8 mr-8
      "
          >
            {groupedData && Object.keys(groupedData).length > 0 ? (
                Object.entries(groupedData).map(([date, entries]) => {
                  return renderCard(date, entries);
                })
            ) : (
                <div>
                  <div
                      className="
            flex flex-col items-center justify-center w-full
            "
                  >
                    <img src={NoResultImage} alt="No result"/>
                    <p className={`${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
                      {t("no_data_found_message")}{" "}
                      <strong>
                        {getMonth(currentDate)} {currentDate.getFullYear()}
                      </strong>
                    </p>
                  </div>
                </div>
            )}
          </div>
        </div>
  );
}

export default DailyJournal;
