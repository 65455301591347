import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/reports.scss";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";

const ReportTab = ({currentPage, page, name, changePage}) => {
    let {t} = useTranslation();
    return (
        <div className={`report-btn ${currentPage === page ? "active" : ""}`} onClick={() => changePage(page)}>
            {t(name)}
        </div>
    )
}

const Report = () => {
    const navigate = useNavigate();
    const [reportPage, setReportPage] = useState('overview');
    const theme = useTheme();

  useEffect(() => {
    navigate("/reports/overview");
  }, []);

  const changePage = (page) => {
      setReportPage(page);
      navigate(`/reports/${page}`)
  }

  return (
    <div className={`report-btn-container ${theme.getThemeClass()}`}>
        <div className="flex justify-between flex-wrap">
            <ReportTab currentPage={reportPage} page={'overview'} changePage={changePage} name={'overview_report_page'} />
            <ReportTab currentPage={reportPage} page={'days'} changePage={changePage} name={'days_report_page'} />
            <ReportTab currentPage={reportPage} page={'months'} changePage={changePage} name={'months_report_page'} />
            <ReportTab currentPage={reportPage} page={'setups'} changePage={changePage} name={'setup_report_page'} />
            <ReportTab currentPage={reportPage} page={'winLossDistribution'} changePage={changePage} name={'reports.tabs.win_loss_distribution_report'} />
            <ReportTab currentPage={reportPage} page={'riskRewardRatio'} changePage={changePage} name={'reports.tabs.risk_reward_ratio_report'} />
        </div>
    </div>
  );
};

export default Report;
