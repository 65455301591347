import "../../styles/InnerPage.scss";
import {useContext, useEffect, useState} from "react";
import {sendGet, sendPost, sendPut} from "../../../common/request";
import links from "../../../common/links";
import Loader from "../../components/Loader";
import { setUser as setReduxUser } from "../../../redux/userActions.js/action";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../../Providers/ThemeContext";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import Checkbox from "../../components/checkbox";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function SubscriptionPage() {
  let [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [remainingDays, setRemainingDays] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const {packages} = useSelector(state => state.data);
  const {t, i18n} = useTranslation()

  let loadUser = async () => {
    setIsLoading(true);
    let response = await sendGet(links.GetUser, true);
    if (!response.error) {
      setUser(response.user);
      dispatch(
        setReduxUser({
          user: response.user,
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser()
  }, []);

  const toDays = (ms) => {
    return Math.ceil(ms / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    if(user && user.subscription) {
      const startDate = new Date(user.subscription.purchaseDate);
      const endDate = new Date(user.subscription.endDate);
      const currentDate = new Date();
      const totalDays = toDays(endDate - startDate)
      const daysRemaining = Math.max(toDays(endDate - currentDate), 0);
      const daysPast = totalDays - daysRemaining;
      const width = (daysRemaining / totalDays) * 100;
      const left = (daysPast / totalDays) * 100
      setRemainingDays(daysRemaining)
      const bar = document.querySelector('.days-bar .bar-fill');
      const legend = document.querySelector('.days-bar .legend');
      if(bar) {
        bar.style.width = `${width}%`;
        bar.style.left = `${left}%`;
      }

      if(legend) {
        legend.style.setProperty('--position', `${left}%`);
        legend.style.setProperty('--transform', `${left <= 6 ? 0 : (left >= 94 ? -100 : -50)}%`);
      }

    }
  }, [user]);

  const userPackage = packages.filter(pack => pack.name === user.subscription?.package)[0];

  const updateSubscriptionRenewal = async (autoRenew) => {

    setIsLoading(true);
    try {
      const response = await sendPut(links.UpdateSubscriptionRenewal, {autoRenew}, true, 'application/json', true);
      if(response.error) {
        toast.error(response.error)
      }
      else {
        await loadUser();
      }
    }
    catch (error) {
      console.log(error);
      toast.error(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const onCancelDowngrade = async () => {
    setIsLoading(true);
    const response = await sendPost(links.CancelSubscriptionDowngrade, {}, true, 'application/json', true);
    if(response.error) {
      console.log(response.error);
      toast.error(response.error);
    }
    else {
      await loadUser();
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className={`data-page ${theme.getThemeClass()}`}>
          <div className="data-page-body">
            <div className='subscription-section'>
              <div className="header">
                <div className="heading">{t("settings.subscription.heading")}</div>
                <div className="buttons">
                  <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                    {t("settings.subscription.fields.change_plan_button")}
                  </div>
                  {
                    user.downgrade ?
                        <div className="danger-outline-btn" onClick={onCancelDowngrade}>
                          {t("settings.subscription.fields.cancel_downgrade")}
                        </div> : <></>
                  }
                </div>
              </div>
              {
                user.subscription ?
                    <div className="content">
                      <div className="row">
                        <div className="col">
                          <div className="label">
                            {t("settings.subscription.fields.package")}
                          </div>
                          <div className={`value ${userPackage?.packageClass}`}>
                            {userPackage.localizedContent[i18n.language].name}
                          </div>
                        </div>
                        <div className="col">
                          <div className="label">
                            {t("settings.subscription.fields.charges")}
                          </div>
                          <div className="value">
                            <span
                                className='price'>${user?.subscription?.price.toLocaleString()}</span> per {user.subscription.period}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="label">
                            {t("settings.subscription.fields.purchaseDate")}
                          </div>
                          <div className="value">
                            {moment(user.subscription.purchaseDate).format("MMMM DD, YYYY")}
                          </div>
                        </div>
                        <div className="col">
                          <div className="label">
                            {user.subscription.autoRenew ? t("settings.subscription.fields.renewalDate") : t("settings.subscription.fields.endDate")}
                          </div>
                          <div className="value">
                            {moment(user.subscription.endDate).format("MMMM DD, YYYY")}
                          </div>
                        </div>
                      </div>
                      {
                        user.downgrade ?
                            <div className="row">
                              <div className="col">
                                <div className="label">
                                  {t("settings.subscription.fields.nextPackage")}
                                </div>
                                <div className="value">
                                  {user.downgrade.nextPackage}
                                </div>
                              </div>
                              <div className="col">
                                <div className="label">
                                  {t("settings.subscription.fields.packagePeriod")}
                                </div>
                                <div className="value">
                                  {user.downgrade.period === 'month' ? t("landing_page.pricing_section.monthly_button") : t("landing_page.pricing_section.yearly_button")}
                                </div>
                              </div>
                            </div> : <></>
                      }
                      <div className="horizontal-container">
                        <Checkbox title={t("settings.subscription.fields.autoRenew")} value={user.subscription.autoRenew}
                                  onChange={value => updateSubscriptionRenewal(value)}/>
                      </div>
                      <div className="days-bar-container">
                        <div className="days-bar">
                          <div className="bar-bg"></div>
                          <div className="bar-fill"></div>
                          <div className="legend">
                            <div className="label">
                              {t("settings.subscription.fields.remainingDays")}
                            </div>
                            <div className="value">
                              {remainingDays} days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="empty-message">
                      No Subscription Currently Exists for the User
                    </div>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubscriptionPage;
