import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

function ConfirmationModal({handleCloseModal, handleConfirmModal, message, heading, isDelete}) {
    const {t} = useTranslation();
    
    const closeModal = () => {
        if(handleCloseModal) {
            handleCloseModal();
        }
    }

    const confirmModal = () => {
        if(handleConfirmModal) {
            handleConfirmModal();
        }
    }

    return (
        <div>
            <div className="modal-overlay">
                <div className="modal dark">
                    <div className="modal-header">
                        <div className="title">{heading}</div>
                        <div className="close-btn" onClick={closeModal}>
                            <MdClose />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="message">
                            {message}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className={`${isDelete ? 'primary-btn' : 'danger-btn'}`} onClick={closeModal}>
                            {t("cancel")}
                        </button>
                        <button className={`${isDelete ? 'danger-btn' : 'primary-btn'}`} onClick={confirmModal}>
                            {t("confirm_action")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal;