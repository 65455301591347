//Sends the get request to the specified route
import { getToken } from "./token"

async function sendGet(url, useToken= false) {
    let token = getToken();
    try {
        let headers = {
            'Content-Type': 'application/json',
        };

        if (useToken) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        let response = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        if(response.status === 200) {
            return await response.json();
        }
        else if(response.status === 401) {
            return {error: "Unauthorized", status: 401}
        }
        else {
            return await response.json();
        }

    }
    catch (error) {
        return {error: error}
    }
}

async function sendPost(url, body, useToken= false, contentType= 'application/json', useContentType= true) {
    let token = getToken();
    let headers = {};

    if(useContentType)
        headers['Content-Type'] = contentType;

    if (useToken) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    let options = {
        mode: 'cors',
        method: 'POST',
        headers: headers,
        credentials: 'include',
    };

    if(contentType !== 'application/json') {
        options.body = body;
    } else {
        options.body = JSON.stringify(body);
    }

    let response = await fetch(url, options);
    if(response.status === 200) {
        let text = await response.text();
        if(text === '') {
            return {
                error: ''
            }
        } else {
            return JSON.parse(text)
        }
    }
    else {
        let text = await response.text();
        if(text) {
            const json = JSON.parse(text);
            return {
                error: json.error,
            }
        }
        else {
            return {
                error: response.statusText,
            }
        }
    }
}

async function sendPut(url, body, useToken= false, contentType= 'application/json', useContentType= true) {
    let token = getToken();
    let headers = {};

    if(useContentType)
        headers['Content-Type'] = contentType;

    if (useToken) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    let options = {
        mode: 'cors',
        method: 'PUT',
        headers: headers,
    };

    if(contentType != 'application/json') {
        options.body = body;
    } else {
        options.body = JSON.stringify(body);
    }

    let response = await fetch(url, options);
    if(response.status == 200) {
        let text = await response.text();
        if(text == '') {
            return {
                error: ''
            }
        } else {
            return JSON.parse(text)
        }
    }
    else {
        let text = await response.text();
        if(text) {
            const json = JSON.parse(text);
            return {
                error: json.error,
            }
        }
        else {
            return {
                error: response.statusText,
            }
        }
    }
}

async function sendDelete(url, body, useToken= false) {
    let token = getToken();
    let headers = {
        'Content-Type': 'application/json',
    };

    if (useToken) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    let response = await fetch(url, {
        mode: 'cors',
        method: 'DELETE',
        headers: headers,
        body: body ? JSON.stringify(body) : ''
    });
    if(response.status === 200) {
        let text = await response.text();
        if(text === '') {
            return {
                error: ''
            }
        } else {
            return JSON.parse(text)
        }
    }
    else {
        let text = await response.text();
        if(text) {
            const json = JSON.parse(text);
            return {
                error: json.error,
            }
        }
        else {
            return {
                error: response.statusText,
            }
        }
    }
}

export {sendGet, sendPost, sendDelete, sendPut}