export const setUser = (user) => ({
  type: "SET_USER",
  payload: user,
});

export const fetchingUser = () => ({
  type: "FETCHING_USER",
});

export const setError = (error) => ({
  type: "SET_ERROR",
  payload: error,
})