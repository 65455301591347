import Logo from "../../images/logo/logo-dark-small.png";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div className="footer-container front">
            <div className="footer-background">
                <div className="footer">
                    <div className="info-section">
                        <div className="logo">
                            <img src={Logo} alt="Logo"/>
                        </div>
                        <div className="description">
                            {t("landing_page.footer.description")}
                        </div>
                    </div>
                    <div className="links-section">
                        <div className="links-content">
                            <div className="heading">
                                {t("landing_page.footer.links.heading")}
                            </div>
                            <div className="links">
                                <div className="link" onClick={() => navigate('/')}>{t("landing_page.footer.links.items.0")}</div>
                                <div className="link" onClick={() => navigate('/pricing')}>{t("landing_page.footer.links.items.1")}</div>
                                <div className="link" onClick={() => navigate('/dashboard')}>{t("landing_page.footer.links.items.2")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-section">
                        <div className="contact-content">
                            <div className="heading">{t("landing_page.footer.contact.heading")}</div>
                            <div className="contact-item">
                                <div className="label">{t("landing_page.footer.contact.items.0.heading")}</div>
                                <div className="value">contact@tradingprozess.com</div>
                            </div>
                            <div className="contact-item">
                                <div className="label">{t("landing_page.footer.contact.items.1.heading")}</div>
                                <div className="social-icons">
                                    <div className="social-icon">
                                        <FaFacebookF/>
                                    </div>
                                    <div className="social-icon">
                                        <FaTwitter/>
                                    </div>
                                    <div className="social-icon">
                                        <FaInstagram/>
                                    </div>
                                    <div className="social-icon">
                                        <FaLinkedinIn/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;