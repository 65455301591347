import {Line} from "react-chartjs-2";
import {useTheme} from "../../Providers/ThemeContext";
import {useEffect, useState} from "react";
import moment from "moment";
import currencyFormat from "../../../common/format";
import {useTranslation} from "react-i18next";

const RunningPNL = ({trade}) => {

    const theme = useTheme();
    const {t} = useTranslation()
    const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
    let lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.3,
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                },
                border: {
                    color: gridColor
                },
            },
            y: {
                border: {
                    color: gridColor
                },
                grid: {
                    color: gridColor,
                    tickColor: gridColor
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        return currencyFormat.format(value);
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };
    const [areaData, setAreaData] = useState({
        labels: [],
        datasets: [
            {
                label: "Running PnL",
                data: [],
                borderWidth: 2,
                borderColor: '#2d90cc',
                fill: {
                    target: 'origin',
                    above: theme.theme === 'dark' ? 'rgba(41, 214, 168, 0.6)' : 'rgba(41, 214, 168, 0.2)',
                    below: theme.theme === 'dark' ? 'rgba(236, 120, 125, 0.6)' : 'rgba(236, 120, 125, 0.2)'
                },
                pointRadius: 0,
            },
        ],
    });

    useEffect(() => {
        if(trade && trade.Contracts) {
            const labels = [];
            const data = [];

            for(let i = 0; i < trade.Contracts.length; i+=1) {
                const contract = trade.Contracts[i];
                labels.push(moment(contract.OpenTime).format('HH:mm'));

                //Previous PNL
                if(i > 0) {
                    data.push(data[i - 1] + contract.PnL);
                }
                else {
                    data.push(contract.PnL);
                }
            }
            setAreaData({
                labels: labels,
                datasets: [
                    {
                        label: "Running PnL",
                        data: data,
                        borderWidth: 2,
                        borderColor: '#2d90cc',
                        fill: {
                            target: 'origin',
                            above: theme.theme === 'dark' ? 'rgba(41, 214, 168, 0.6)' : 'rgba(41, 214, 168, 0.2)',
                            below: theme.theme === 'dark' ? 'rgba(236, 120, 125, 0.6)' : 'rgba(236, 120, 125, 0.2)'
                        },
                        pointRadius: 2,
                    },
                ],
            })
        }
    }, [trade]);

    return (
        <div className="running-pnl item">
            <div className="heading">
                {t("trade_page.running_pnl.heading")}
            </div>
            <div className="chart">
                <Line
                    data={areaData}
                    options={lineOptions}
                />
            </div>
        </div>
    )
}

export default RunningPNL;