import "../styles/popup.scss"
import {MdClose} from "react-icons/md";
import {useEffect, useState} from "react";
import {sendGet} from "../../common/request";
import links from "../../common/links";
import useSubAccount from "../hooks/useSubAccount";
import currencyFormat from "../../common/format";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

const DailyInsights = () => {
    const [show, setShow] = useState(false);
    const subAccount = useSubAccount();
    const [insightsData, setInsightsData] = useState({});
    const {insightsShown, insightsSubAccount} = useSelector(state => state.user)
    const dispatch = useDispatch();
    const theme = useTheme();
    const {t} = useTranslation();

    const fetchDayInsights = async (subAccountId) => {
        const response = await sendGet(links.GetDayInsights(subAccountId), true);
        if(response.error) {
            console.log(response.error);
        }
        else {
            setTimeout(() => {
                if(response.trades > 0) {
                    dispatch({type: 'SET_INSIGHTS', payload: {insightsShown: true, insightsSubAccount: subAccountId}});
                    setShow(true);
                }
                setInsightsData(response);
            }, [10000])
        }
    }

    useEffect(() => {
        if(subAccount && insightsSubAccount !== subAccount.id) {
            fetchDayInsights(subAccount.id)
        }
    }, [subAccount, insightsShown]);

    return (
        <div className={`popup ${theme.getThemeClass()} daily-insights ${show ? 'show' : 'hide'}`}>
            <div className="header">
                <div className="title">{t("daily_insights.title")}</div>
                <div className="close-icon" onClick={() => setShow(false)}>
                    <MdClose />
                </div>
            </div>
            <div className="separator"></div>
            <div className="body">
                <div className="items">
                    <div className="item">
                        {t("daily_insights.items.0")}: <span className="value">{insightsData.trades}</span>
                    </div>
                    <div className="item">
                        <div className="vertical-separator"></div>
                    </div>
                    <div className="item">
                        {t("daily_insights.items.1")}: <span className='value positive'>{currencyFormat.format(insightsData.profit)}</span>
                    </div>
                    <div className="item">
                        {t("daily_insights.items.2")}: <span className='value negative'>{currencyFormat.format(insightsData.loss)}</span>
                    </div>
                    <div className="item">
                        <div className="vertical-separator"></div>
                    </div>
                    <div className="item">
                        {t("daily_insights.items.3")}: <span className="value">{insightsData.maxContracts}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyInsights;