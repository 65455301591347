import Wallet from "../../images/wallet-dynamic-color.png";
import Mobile from "../../images/mobile-dynamic-color.png";
import Target from "../../images/target-dynamic-color.png";
import ChatText from "../../images/chat-text-dynamic-color.png";
import ChatBubble from "../../images/chat-bubble-dynamic-color.png";
import Card from "../../images/card-dynamic-color.png";
import Calendar from "../../images/calender-dynamic-color.png";
import {useRef} from "react";
import {useTranslation} from "react-i18next";

const WhyUsSection = () => {

    const circleRef = useRef(undefined);
    const {t} = useTranslation();

    const onMouseEnter = () => {
        if(circleRef.current) {
            circleRef.current.style.opacity = 1
        }
    }

    const onMouseMove = (e) => {
        if(circleRef.current) {
           const boundingRect = e.currentTarget.getBoundingClientRect();
           const top = e.clientY - boundingRect.top;
           const left = e.clientX - boundingRect.left;
           circleRef.current.style.top = `${top}px`;
           circleRef.current.style.left = `${left}px`;
        }
    }

    const onMouseLeave = () => {
        if(circleRef.current) {
            circleRef.current.style.opacity = 0;
        }
    }

    return (
        <div className='why-us-section front'>
            <div className="content">
                <div className="title">
                    <div className="heading">{t("landing_page.why_us_section.title")}</div>
                    <div className="description">{t("landing_page.why_us_section.description")}</div>
                </div>
                <div className="items-grid-container" onMouseMove={onMouseMove} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <div className="items-grid">
                        <div className="circle" ref={circleRef}></div>
                        <div className="row row-1">
                            <div className="item">
                                <img src={ChatBubble} alt={'Chat Bubble'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.0.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.0.description")}</div>
                            </div>
                            <div className="item">
                                <img src={Calendar} alt={'Calendar'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.1.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.1.description")}</div>
                            </div>
                        </div>
                        <div className="row row-2">
                            <div className="item">
                                <img src={Wallet} alt={'wallet'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.2.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.2.description")}</div>
                            </div>
                            <div className="item">
                                <img src={Mobile} alt={'Mobile'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.3.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.3.description")}</div>
                            </div>
                        </div>
                        <div className="row row-3">
                            <div className="item">
                                <img src={Target} alt={'Target'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.4.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.4.description")}</div>
                            </div>
                            <div className="item">
                                <img src={ChatText} alt={'Chat'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.5.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.5.description")}</div>
                            </div>
                            <div className="item">
                                <img src={Card} alt={'Payment'} className={'icon'}/>
                                <div className="heading">{t("landing_page.why_us_section.items.6.heading")}</div>
                                <div
                                    className="description">{t("landing_page.why_us_section.items.6.description")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUsSection;