import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import links from "../../../common/links";
import { useSelector } from "react-redux";
import {getAveragePlannedRMultiple, getAverageRealizedRMultiple} from "../../../common/methods";
import {useTheme} from "../../Providers/ThemeContext";
import currencyFormat from "../../../common/format";
import permissions from "../../data/Permissions.json";
import ViewPermission from "../permissions/ViewPermission";

const OverviewReport = () => {
  const selectedAccount = useSelector(
    (state) => state.accounts.selectedAccounts
  );
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const accounts = useSelector((state) => state?.user?.user);
  const [data, setData] = useState([]);
  const [daysPnL, setDaysPnL] = useState([]);
  const theme = useTheme();

  const [objectData, setObjectData] = useState({
    Total_PNL: "",
    Average_Daily_volume: 0,
    Average_Winning_Trade: 0,
    Average_Losing_Trade: 0,
    Total_Number_of_Trades: 0,
    Number_of_Winning_Trades: 0,
    Number_of_Losing_Trades: 0,
    Number_of_Breakeven_Trades: 0,
    Max_Consecutive_Wins: 0,
    Max_Consecutive_Losses: 0,
    Total_Commission: 0,
    Total_Fees: 0,
    Total_Swap: 0,
    Largest_Profit: 0,
    Largest_Loss: 0,
    Average_Hold_Time_All_Trades: 0,
    Average_Hold_Time_Winning_Trades: 0,
    Average_Hold_Time_Losing_Trades: 0,
    Average_Hold_Time_Scratch_Trades: 0,
    Average_Trade_PnL: 0,
    Profit_Factor: 0,
    Open_Trades: 0,
    Total_Trading_Days: 0,
    Winning_Days: 0,
    Losing_Days: 0,
    Breakeven_Days: 0,
    Logged_Days: 0,
    Max_Consecutive_Winning_Days: 0,
    Max_Consecutive_Losing_Days: 0,
    Average_Daily_PnL: 0,
    Average_Winning_Day_PnL: 0,
    Average_Losing_Day_PnL: 0,
    Largest_Profitable_Day: 0,
    Largest_Losing_Day: 0,
    Average_Planned_R_Multiple: 0,
    Average_Realized_R_Multiple: 0,
    Trade_Expectancy: 0,
  });
  const [bestPnl, setBestPnL] = useState(0);
  const [bestPnLMonth, setBestPnLMonth] = useState("");
  const [lowestPnL, setLowestPnL] = useState(0);
  const [lowPnLMonth, setLowPnLMonth] = useState("");
  const [averagePnL, setAveragePnL] = useState(0);
  let { t } = useTranslation();

  let getMonth = (date) => {
    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return months[date.getMonth()];
  };

  let getDate = (date) => {
    let month = date.getMonth() + 1;
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${month < 10 ? `0${month}` : month}-${date.getFullYear()}`;
  }

  const subUserId = accounts?.user?.subAccounts?.filter(
    (account) => account?.name === selectedAccount
  )[0]?.id
    ? accounts?.user?.subAccounts?.filter(
        (account) => account?.name === selectedAccount
      )[0]?.id
    : accounts?.user?.subAccounts?.[0]?.id;

  useEffect(() => {
    async function fetchSubAccounts() {
      try {
        let token = localStorage.getItem("token");
        const response = await fetch(links.GetTrades(subUserId), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        if (data) {
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching sub accounts:", error);
      }
    }

    if(subUserId) {
      fetchSubAccounts();
    }
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  let UsDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const entries = Object.entries(objectData);
  let midEntry = (entries.length / 2).toFixed();
  const firstColumn = entries.slice(0, midEntry);
  const secondColumn = entries.slice(midEntry);
  let totalPnL = 0;
  useEffect(
    () => {

      let monthsPNL = {};
      let daysPNL = {};
      let totalVolume = 0;
      let maxConsecutiveWins = 0;
      let maxConsecutiveLosses = 0;
      let maxConsecutiveWinningDays = 0;
      let maxConsecutiveLosingDays = 0;
      let numberOfConsecutiveSessions = 0;
      let typeOfConsecutiveSessions = "breakeven";
      let largestProfit = 0;
      let largestLoss = 0;
      let winningTradesHoldTime = 0;
      let losingTradesHoldTime = 0;

      if(!data) {
        return;
      }

      let sortedData = data?.sort((a, b) => {
        let d1 = new Date(a.data.CloseTime);
        let d2 = new Date(b.data.CloseTime);
        return d1 - d2;
      });

      let trades = data?.map(trade => trade.data);

      //Finding the PNL for each month
      sortedData.map((item, index) => {

        if(!item)
          return;

        const closeTime = new Date(item.data.CloseTime);
        const itemPNL = parseFloat(item.data.PnL);
        let monthName = getMonth(closeTime);
        let year = closeTime.getFullYear();
        let fullName = `${monthName} ${year}`;
        let fullDate = getDate(closeTime);
        if(!(fullName in monthsPNL)) {
          monthsPNL[fullName] = 0;
        }
        monthsPNL[fullName] += itemPNL;

        if(!(fullDate in daysPNL)) {
          daysPNL[fullDate] = {profit: 0, loss: 0, winningTrades: 0, losingTrades: 0, breakEvenTrades: 0}
        }

        if(itemPNL > 0) {
          daysPNL[fullDate].profit += itemPNL;
          daysPNL[fullDate].winningTrades += 1;
        } else if(itemPNL < 0) {
          daysPNL[fullDate].loss += itemPNL;
          daysPNL[fullDate].losingTrades += 1;
        } else {
          daysPNL[fullDate].breakEvenTrades += 1;
        }

        let newSelectedDate = new Date(selectedDate);
        let newEndDate = new Date(endDate);

        //If the date is in the selected date range
        if(endDate >= newSelectedDate && endDate <= newEndDate) {
          
          if(itemPNL > largestProfit) {
            largestProfit = itemPNL;
          } else if(itemPNL < largestLoss) {
            largestLoss = itemPNL;
          }

          let openTime = new Date(item.data.OpenTime);
          //Hold time in minutes
          let holdTime = (closeTime - openTime) / (1000 * 60);

          if(itemPNL > 0) {
            winningTradesHoldTime += holdTime;
          } else if(itemPNL < 0) {
            losingTradesHoldTime += holdTime;
          }

          let tradeType = itemPNL > 0 ? "profit" : (itemPNL < 0 ? "loss" : "breakeven");
          if(typeOfConsecutiveSessions === "breakeven" ) {
            typeOfConsecutiveSessions = tradeType;
            numberOfConsecutiveSessions = 1;
            if(tradeType === "profit") {
              maxConsecutiveWins = 1;
            } else if(tradeType === "loss") {
              maxConsecutiveLosses = 1;
            }
          } else {
            //Next item in the sequence
            if(tradeType === typeOfConsecutiveSessions) {
              numberOfConsecutiveSessions += 1;
              if(tradeType === "profit" && numberOfConsecutiveSessions > maxConsecutiveWins) {
                maxConsecutiveWins = numberOfConsecutiveSessions;
              } else if(tradeType === "loss" && numberOfConsecutiveSessions > maxConsecutiveLosses) {
                maxConsecutiveLosses = numberOfConsecutiveSessions;
              }
            } else {
              typeOfConsecutiveSessions = "breakeven";
            }
          }

        }

      });

      //Finding and setting the lowest and highest months
      let totalMonthPnL = 0;
      let maxMonth = "";
      let maxValue = 0;
      let minMonth = "";
      let minValue = 0;
      let first = true;
      //Finding the lowest and best month with their PnL values
      for(let month of Object.keys(monthsPNL)) {
        let value = monthsPNL[month];
        totalMonthPnL += value;
        if(first) {
          maxMonth = month;
          minMonth = month;
          maxValue = monthsPNL[month];
          minValue = monthsPNL[month];
          first = false;
          continue;
        }

        if(value > maxValue) {
          maxValue = value;
          maxMonth = month;
        } else if(value < minValue) {
          minValue = value;
          minMonth = month;
        }
      }

      setBestPnL(maxValue);
      setBestPnLMonth(maxMonth);
      setLowestPnL(minValue);
      setLowPnLMonth(minMonth);
      setAveragePnL(totalMonthPnL / Object.keys(monthsPNL).length)

      let totalProfit = 0;
      let totalLoss = 0;
      let winningTrades = 0;
      let winningDays = 0;
      let losingDays = 0;
      let losingTrades = 0;
      let totalDays = 0;
      let breakEvenTrades = 0;
      let breakEvenDays = 0;
      let winningDaysPnL = 0;
      let losingDaysPnL = 0;
      let largestProfitableDay = 0;
      let largestLossingDay = 0;
      typeOfConsecutiveSessions = "breakeven";

      Object.keys(daysPNL).forEach(item => {

        let dayData = daysPNL[item];
        let date = moment(item, "DD-MM-YYYY").toDate();
        let newSelectedDate = new Date(selectedDate);
        let newEndDate = new Date(endDate);

        //If the day does not exists in the date range then skip it
        if(date < newSelectedDate || date > newEndDate) {
          return;
        }

        let dayPNL = dayData.profit + dayData.loss;

        if(dayPNL > 0) {
          winningDays += 1;
          winningDaysPnL += dayPNL;
        } else if(dayPNL < 0) {
          losingDays += 1;
          losingDaysPnL += dayPNL;
        } else {
          breakEvenDays += 1;
        }

        if(dayPNL > largestProfitableDay) {
          largestProfitableDay = dayPNL;
        } else if(dayPNL < largestLossingDay) {
          largestLossingDay = dayPNL;
        }

        let tradeType = dayPNL > 0 ? "profit" : (dayPNL < 0 ? "loss" : "breakeven");
        if(typeOfConsecutiveSessions === "breakeven" ) {
          typeOfConsecutiveSessions = tradeType;
          numberOfConsecutiveSessions = 1;
          if(tradeType === "profit") {
            maxConsecutiveWinningDays = 1;
          } else if(tradeType === "loss") {
            maxConsecutiveLosingDays = 1;
          }
        } else {
          //Next item in the sequence
          if(tradeType === typeOfConsecutiveSessions) {
            numberOfConsecutiveSessions += 1;
            if(tradeType === "profit" && numberOfConsecutiveSessions > maxConsecutiveWinningDays) {
              maxConsecutiveWinningDays = numberOfConsecutiveSessions;
            } else if(tradeType === "loss" && numberOfConsecutiveSessions > maxConsecutiveLosingDays) {
              maxConsecutiveLosingDays = numberOfConsecutiveSessions;
            }
          } else {
            typeOfConsecutiveSessions = "breakeven";
          }
        }

        totalDays += 1;
        totalPnL += dayPNL;
        totalVolume += dayData.winningTrades + dayData.losingTrades;
        totalProfit += dayData.profit;
        totalLoss += dayData.loss;
        winningTrades += dayData.winningTrades;
        losingTrades += dayData.losingTrades;
        breakEvenTrades += dayData.breakEvenTrades
      });

      let totalTrades = winningTrades + losingTrades + breakEvenTrades;
      let expectancy = 'N/A';
      if(totalTrades > 0 && winningTrades > 0 && losingTrades > 0) {
        let winRate = winningTrades / totalTrades;
        let averageWin = totalProfit / winningTrades;
        let averageLoss = totalLoss / losingTrades;
        expectancy = `$${(winRate * averageWin - (1 - winRate) * averageLoss).toFixed(2)}`;
      }
      setObjectData({
          ...objectData,
          Total_PNL: UsDollar.format(totalPnL),
          Average_Daily_volume: totalDays === 0 ? 'N/A' : (totalVolume / totalDays).toFixed(2),
          Average_Winning_Trade: winningTrades === 0 ? 'N/A' : UsDollar.format(totalProfit / winningTrades),
          Average_Losing_Trade: losingTrades === 0 ? 'N/A' : UsDollar.format((totalLoss / losingTrades)),
          Total_Number_of_Trades: winningTrades + losingTrades + breakEvenTrades,
          Number_of_Winning_Trades: winningTrades,
          Number_of_Losing_Trades: losingTrades,
          Number_of_Breakeven_Trades: breakEvenTrades,
          Max_Consecutive_Wins: winningTrades > 0 ? (maxConsecutiveWins === 0 ? 1 : maxConsecutiveWins) : 0,
          Max_Consecutive_Losses: losingTrades > 0 ? (maxConsecutiveLosses === 0 ? 1 : maxConsecutiveLosses) : 0,
          Total_Commission: 0,
          Total_Fees: 0,
          Total_Swap: 0,
          Largest_Profit: UsDollar.format(largestProfit),
          Largest_Loss: UsDollar.format(largestLoss),
          Average_Hold_Time_All_Trades: winningTrades + losingTrades === 0 ? 'N/A' : `${((winningTradesHoldTime + losingTradesHoldTime) / (winningTrades + losingTrades)).toFixed()} minutes`,
          Average_Hold_Time_Winning_Trades: winningTrades === 0 ? 'N/A' : `${(winningTradesHoldTime / winningTrades).toFixed()} minutes`, 
          Average_Hold_Time_Losing_Trades: losingTrades === 0 ? 'N/A' : `${(losingTradesHoldTime / losingTrades).toFixed()} minutes`, 
          Average_Hold_Time_Scratch_Trades: 'N/A',
          Average_Trade_PnL: UsDollar.format(totalPnL / (winningTrades + losingTrades + breakEvenTrades)),
          Profit_Factor: totalLoss === 0 ? 'N/A' : (totalProfit / totalLoss).toFixed(2),
          Open_Trades: 0,
          Total_Trading_Days: totalDays,
          Winning_Days: winningDays,
          Losing_Days: losingDays,
          Breakeven_Days: breakEvenDays,
          Max_Consecutive_Winning_Days: winningDays > 0 ? (maxConsecutiveWinningDays === 0 ? 1 : maxConsecutiveWinningDays) : 0,
          Max_Consecutive_Losing_Days: losingDays > 0 ? (maxConsecutiveLosingDays === 0 ? 1 : maxConsecutiveLosingDays) : 0,
          Average_Daily_PnL: winningDays + losingDays === 0 ? 'N/A' : UsDollar.format(totalPnL / (winningDays + losingDays)),
          Average_Winning_Day_PnL: winningDays === 0 ? 'N/A' : UsDollar.format(winningDaysPnL / winningDays),
          Average_Losing_Day_PnL: losingDays === 0 ? 'N/A' : UsDollar.format(losingDaysPnL / losingDays),
          Largest_Profitable_Day: UsDollar.format(largestProfitableDay),
          Largest_Losing_Day: UsDollar.format(largestLossingDay),
          Average_Planned_R_Multiple: `${getAveragePlannedRMultiple(trades)}R`,
          Average_Realized_R_Multiple: `${getAverageRealizedRMultiple(trades)}R`,
          Trade_Expectancy: expectancy,
        });

        setDaysPnL(daysPNL);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      accounts?.user?.subAccounts,
      data?.length,
      data?.subUserId,
      selectedAccount,
      subUserId,
      selectedDate,
      endDate,
    ]
  );

  const green = "#29D6A8";
  const red = "#ec787d";
  const primary = "#FF9326";

  const filteredData = Object.keys(daysPnL).filter(date => {
    let newSelectedDate = new Date(selectedDate);
    let newEndDate = new Date(endDate);
    let itemDate = moment(date, "DD-MM-YYYY").toDate();
    return itemDate >= newSelectedDate && itemDate <= newEndDate;
  });

  const accumulatedData =  filteredData.map(date => {
    let data = daysPnL[date];
    return {
      date: date,
      value: data.profit + data.loss,
    }
  });

  let cumulativePnL = 0;
  const cumulativeData = filteredData.map(date => {
    let dayData = daysPnL[date];
    cumulativePnL +=  dayData.profit + dayData.loss;
    return {
      date: date,
      value: cumulativePnL,
    };
  });
  if(cumulativeData.length > 0) {
    let firstDate = moment(cumulativeData[0].date, "DD-MM-YYYY").toDate();
    firstDate.setDate(firstDate.getDate() - 1);
    cumulativeData.unshift({
      date: getDate(firstDate),
      value: 0,
    })
  }

  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  // label by month
  let lineOptions = {
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        grid: {
          color: gridColor,
          tickColor: gridColor
        },
        border: {
          color: gridColor
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  let getTotalPNL = () => {
    return parseFloat(objectData.Total_PNL?.replace("$", ""));
  }

  const areaData = {
    labels: cumulativeData.map((item) => {
      // get day of the week
      const day = moment(item.date, "DD-MM-YYYY").toDate().toDateString();
      return day;
    }),
    datasets: [
      {
        label: "Cumulative P&L",
        data: cumulativeData.map(item => item.value),
        borderColor: getTotalPNL() > 0 ? "rgba(41, 214, 168, 0.8)"
        : "rgba(236, 120, 125, 0.8)",
        backgroundColor: getTotalPNL() > 0 ? "rgba(41, 214, 168, 0.4)"
        : "rgba(236, 120, 125, 0.4)",
        pointBackgroundColor: getTotalPNL() > 0 ? "rgba(41, 214, 168, 0.8)"
        : "rgba(236, 120, 125, 0.8)",
        fill: true,
        pointRadius: 3,
      },
    ],
  };

  let barChartOptions = {
    maintainAspectRatio: false,
    pointRadius: 0,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        grace: "1",
        grid: {
          color: gridColor,
          tickColor: gridColor
        },
        border: {
          color: gridColor
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const barChartData = {
    labels: accumulatedData.map((item) => {
      // get day of the week
      const day = moment(item.date, "DD-MM-YYYY").toDate().toDateString();
      return day;
    }),
    datasets: [
      {
        label: "Daily P&L",
        indexAxis: "x",
        data: accumulatedData.map(item => item.value),
        borderRadius: 5,
        maxBarThickness: 50,
      },
    ],
  };

  // Dynamically set the background color based on positive or negative value
  barChartData.datasets[0].backgroundColor = barChartData.datasets[0].data.map(
    (value) => (value > 0 ? green : red)
  );

  return (
      <ViewPermission permission={permissions.PAGE.REPORTS.OVERVIEW}>
        <div className="w-full flex flex-col items-center ">
          <div className=" w-[90%]">
            <div className="flex flex-col gap-[0.2rem] w-full mt-[20px] rounded-md"
                 style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
              <div className={`p-[1.5rem] rounded-lg ${theme.theme === 'dark' ? 'bg-transparent' : 'bg-white'}`}>
                <h3 className={`text-xl font-bold ${theme.theme === 'dark' ? 'text-gray-50' : 'text-gray-800'}`}>
                  {t("overview_heading")}
                </h3>
                <p className={`text-sm font-bold capitalize ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-800'}`}>
                  {`${t("from")} ${moment(selectedDate).format("MM/DD/YYYY")} ${t('to')} ${endDate ? moment(endDate).format("MM/DD/YYYY") : moment(selectedDate).format("MM/DD/YYYY")}`}
                </p>
              </div>
              <div className="flex justify-center">
                <div
                    className="flex flex-col md:flex-row gap-[1.5rem] h-[700px] md:h-[400px] mt-[1.5rem] w-[95%] mb-[2rem]">
                  <div
                      className={`flex-1 flex flex-col rounded-lg p-[1rem] h-[100%] gap-2 box-border border ${theme.theme === 'dark' ? 'transparent text-gray-200 border-gray-600' : 'bg-white text-gray-700 border-gray-100'}`}>
                    <div
                        className='font-semibold text-sm'>{t("reports.overview_report.daily_net_cumulative_chart.heading")}
                      <span
                          className='ms-2 font-normal'>({t("reports.overview_report.daily_net_cumulative_chart.dates")})</span>
                    </div>
                    <div className="chart">
                      <Line
                          data={areaData}
                          options={lineOptions}
                      />
                    </div>
                  </div>
                  <div
                      className={`flex-1 flex flex-col rounded-lg p-[1rem] h-[100%] gap-2 box-border border ${theme.theme === 'dark' ? 'transparent text-gray-200 border-gray-600' : 'bg-white text-gray-700 border-gray-100'}`}>
                    <div className='font-semibold text-sm'>{t("reports.overview_report.net_daily_pnl_chart.heading")}
                      <span
                          className='ms-2 font-normal'>({t("reports.overview_report.net_daily_pnl_chart.dates")})</span>
                    </div>
                    <div className="chart">
                      <Bar
                          data={barChartData}
                          options={barChartOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Month Wise Data */}
              <div className="flex flex-row gap-2 justify-end mr-[0rem] mb-[1em] md:mr-[1.5rem]">
                <div className="flex flex-col px-1 md:px-3 py-3 gap-0 rounded-lg">
                  <h2 className={`text-[0.9rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}>
                    {t("overview_best_month")}
                  </h2>
                  <p className={`text-[0.92rem] font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {UsDollar.format(bestPnl)}
                  </p>
                  <p className={`text-[0.8rem] font-bold ${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}>
                    in {bestPnLMonth}
                  </p>
                </div>
                <div className="flex flex-col px-1 md:px-3 py-3 gap-0 rounded-lg">
                  <h2 className={`text-[0.9rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}>
                    {t("overview_lowest_month")}
                  </h2>
                  <p className={`text-[0.92rem] font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {UsDollar.format(lowestPnL)}
                  </p>
                  <p className={`text-[0.8rem] font-bold ${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}>
                    in {lowPnLMonth}
                  </p>
                </div>
                <div className="flex flex-col px-1 md:px-3 py-3 gap-0 rounded-lg ">
                  <h2 className={`text-[0.9rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}>
                    {t("overview_average_month")}
                  </h2>
                  <p className={`text-[0.92rem] font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {UsDollar.format(averagePnL)}
                  </p>
                  <p className={`text-[0.8rem] font-bold ${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}>
                    {t("perMonth")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full px-[1.5rem]">
                <div className="flex flex-col md:flex-row gap-4 mb-4">
                  <div className="w-full md:w-1/2">
                    {firstColumn.map(([key, value], index) => (
                        <div
                            key={index}
                            className={`flex justify-between ${
                                theme.theme === 'dark' ? ((index + 1) % 2 === 0 ? "bg-gray-600" : "bg-gray-650") : ((index + 1) % 2 === 0 ? "bg-white" : "bg-gray-75")
                            }  p-2 md:p-2 rounded-md`}
                        >
                          <p className={`text-[0.93rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-500'}`}>
                            {t(key)}
                          </p>
                          <p className={`text-[0.93rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-500'}`}>
                            {value}
                          </p>
                        </div>
                    ))}
                  </div>
                  <div className="w-full md:w-1/2">
                    {secondColumn.map(([key, value], index) => (
                        <div
                            key={index + 15}
                            className={`flex justify-between ${
                                theme.theme === 'dark' ? ((index) % 2 === 0 ? "bg-gray-600" : "bg-gray-650") : ((index + 1) % 2 === 0 ? "bg-white" : "bg-gray-75")
                            }  p-2 md:p-2 rounded-md`}
                        >
                          <p className={`text-[0.93rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-500'}`}>
                            {t(key)}
                          </p>
                          <p className={`text-[0.93rem] font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-500'}`}>
                            {value}
                          </p>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ViewPermission>
  );
};

export default OverviewReport;
