import Logo from "../../images/logo/logo-dark.png";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader";
import {useDispatch, useSelector} from "react-redux";
import {MdClose, MdMenu} from "react-icons/md";
import {useState} from "react";
import Selectcontrol from "../selectcontrol";
import {sendGet, sendPost} from "../../../common/request";
import links from "../../../common/links";
import i18next from "i18next";
import {setUser as setReduxUser} from "../../../redux/userActions.js/action";
import {changeLanguage as changeReduxLanguage} from "../../../redux/data-actions/action";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import fr from 'moment/locale/fr';
import ar from 'moment/locale/ar';
import de from 'moment/locale/de';

const Navbar = ({currentPage}) => {

    const {user, loading: userLoading} = useSelector(state => state.user);
    const navigate = useNavigate();
    const [navbarShown, setNavbarShown] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {language} = useSelector(state => state.data);
    const {t} = useTranslation();

    const navigateToPage = (page) => {
        navigate(page);
    }

    const languageOptions = ['EN', 'DE']

    const changeLanguage = async (index) => {
        const newLanguage = languageOptions[index];
        await saveProfile(newLanguage);
    }

    let saveProfile = async (lang) => {

        if(!user || !user.user) {
            await i18next.changeLanguage(lang.toLowerCase());
            dispatch(changeReduxLanguage(lang));
            const nLang = lang.toLowerCase();
            if(nLang === 'en') {
                moment.locale(nLang);
            } else if(nLang === 'de') {
                moment.locale(nLang, de);
            } else if(nLang === 'fr') {
                moment.locale(nLang, fr);
            } else if(nLang === 'ar') {
                moment.locale(nLang, ar);
            }
        }
        else {
            setLoading(true);
            const userData = user.user;
            let response = await sendPost(
                links.Profile,
                {
                    id: userData.id,
                    username: userData.username,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    language: lang.toLowerCase(),
                    visibility: userData.visibility,
                    timezone: userData.timezone,
                    theme: userData.theme,
                },
                true, "application/json", true
            );

            if (response.error) {
                console.log(response.error);
            } else {
                await loadUser();
            }
            setLoading(false);
        }
    };

    let loadUser = async () => {
        setLoading(true);
        let response = await sendGet(links.GetUser, true);
        if (!response.error) {
            await i18next.changeLanguage(response.user.language);
            dispatch(
                setReduxUser({
                    user: response.user,
                })
            );
        }
        setLoading(false);
    };

    return (
        <div className="navbar-container front">
            <div className={`navbar ${navbarShown ? 'show' : ''}`}>
                <div className="logo">
                    <img src={Logo} alt="Logo" onClick={() => navigateToPage('/')} />
                </div>
                <div className={`semi-navbar`}>
                    <div className="header">
                        <div className="logo">
                            <img src={Logo} alt="Logo" onClick={() => navigateToPage('/')}/>
                        </div>
                        <div className="nav-toggle-btn" onClick={() => setNavbarShown(false)}>
                            <MdClose />
                        </div>
                    </div>
                    <div className="nav-buttons">
                        {
                            loading ?
                                <Loader height={20} width={30}/> :
                                <div className="nav-select">
                                    <Selectcontrol options={languageOptions}
                                                   selectedIndex={user && user.user ? languageOptions.indexOf(user?.user?.language.toUpperCase()) : languageOptions.indexOf(language)}
                                                   onChange={changeLanguage} themeClass={'dark'}/>
                                </div>
                        }
                    <div className={`nav-button ${currentPage === 'home' ? 'active' : ''}`}
                        onClick={() => navigateToPage('/')}>{t("navbar.home")}
                    </div>
                    <div className={`nav-button ${currentPage === 'pricing' ? 'active' : ''}`}
                             onClick={() => navigateToPage('/pricing')}>{t("navbar.pricing")}
                        </div>
                        {
                            userLoading ?
                                <Loader height={15} width={30}/> :
                                (
                                    user && user.user ?
                                        <div className="nav-button filled"
                                             onClick={() => navigateToPage('/dashboard')}>{t("navbar.dashboard")}</div> :
                                        <>
                                            <div className="nav-button" onClick={() => navigateToPage('/login')}>{t("navbar.login")}
                                            </div>
                                            <div className="nav-button filled"
                                                 onClick={() => navigateToPage('/register')}>{t("navbar.register")}
                                            </div>
                                        </>
                                )
                        }
                    </div>
                </div>
                <div className="nav-toggle-btn" onClick={() => setNavbarShown(true)}>
                    <MdMenu/>
                </div>
            </div>
        </div>
    )
}

export default Navbar;