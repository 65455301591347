import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const ViewPermission = ({permission, children, shallHide}) => {

    const {user} = useSelector((state) => state.user)
    const navigate = useNavigate();

    return (
        <>
            {
                user && user.user && user.user.subscription && user.user.subscription.permissions.includes(permission) ?
                    children :
                    shallHide ?
                        <></> :
                        <div className="view-container auto">
                            <div className="view-bg">
                                {children}
                            </div>
                            <div className="view-card">
                                <div className="info">Oh!!!! It seems that you do not have permission to view this
                                    resource.<br/>Please <strong>Upgrade</strong> your subscription
                                </div>
                                <div className="buttons">
                                    <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                                        Upgrade
                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </>
    )
}

export default ViewPermission;