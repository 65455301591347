export const getPackages = () => ({
  type: "GET_PACKAGES",
});

export const setPackages = (packages) => ({
  type: "PACKAGES_SUCCESSFUL",
  payload: packages,
});

export const setPackagesError = (error) => ({
  type: "PACKAGES_FAILURE",
  payload: error,
})

export const changeLanguage = (language) => ({
  type: "CHANGE_LANGUAGE",
  payload: language,
})