import React, {useState, useEffect, useRef, useContext} from "react";
import Select from "../components/selectcontrol";
import "../styles/community.scss";
import {
  MdOutlineArrowBack,
  MdOutlineUploadFile,
  MdSearch,
  MdChat,
  MdClose,
  MdOutlinePeople,
  MdOutlinePerson,
  MdArrowUpward, MdMoreVert,
} from "react-icons/md";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setTitle} from "../../redux/header/action";
import User from "../components/user";
import GroupMembers from "../components/groupMembers";
import GroupSettings from "../components/groupSettings";
import SearchGroup from "../components/searchGroup";
import AddGroup from "../components/addGroup";
import GroupChat from "../components/groupChat";
import SearchUser from "./../components/searchUser";
import { toast } from "react-toastify";
import {sendDelete, sendGet, sendPost} from '../../common/request';
import Urls from '../../common/links';
import useSubAccount from "../hooks/useSubAccount";
import moment from "moment";
import ImagePlaceholder from "../images/image-placeholder.jpg";
import {inView, motion} from 'framer-motion';
import {LazyLoadImage} from "react-lazy-load-image-component";
import FilePreviewModal from "../components/Modal/FilePreviewModal";
import {setUnReadMessages} from "../../redux/community/action";
import SocketContext from "../contexts/SocketContext";
import ChatSettings from "../components/chatSettings";
import {useTheme} from "../Providers/ThemeContext";
import MessageRightCorner from "../components/icons/MessageRightCorner";
import MessageLeftCorner from "../components/icons/MessageLeftCorner";
import Send from "../components/icons/Send";
import permissions from "../data/Permissions.json";
import ViewPermission from "../components/permissions/ViewPermission";

export default function Community() {
  const unReadMessagesRef = useRef(0);
  const unReadMessages = useSelector(state => state.community.unReadMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const activeViewRef = useRef('chats');
  const [activeView, setActiveView] = useState("chats");
  const [messages, setMessages] = useState([]);
  const [chatPage, setChatPage] = useState(true);
  const [searchCommunity, setSearchCommunity] = useState(false);
  const [searchUser, setSeachUser] = useState(false);
  const [groupPage, setGroupPage] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const selectedUserRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredGroups, setfilteredGroups] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [selectedUsersToInvite, setSelectedUsersToInvite] = useState([]);
  const messagesEndRef = useRef(null);
  const [users, setUsers] = useState([]);
  const chatsRef = useRef([]);
  const [usersList, setUsersList] = useState([]);
  const [inviteableUsers, setInviteableUsers] = useState([]);
  const groupsRef = useRef([]);
  const [groups, setGroups] = useState([]);
  const subAccount = useSubAccount();
  const onMessageFirstLoadRef = useRef(true);
  const MESSAGES_COUNT = 20;
  const currentOffset = useRef(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const {t} = useTranslation();
  const [isPreviewModalShown, setIsPreviewModalShown] = useState(false);
  const [previewFile, setPreviewFile] = useState({fileType: "", url: ""});
  const socketContext = useContext(SocketContext);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isChatDeleteModalShown, setIsChatDeleteModalShown] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    activeViewRef.current = activeView;
  }, [activeView])

  useEffect(() => {
    if(socketContext.socket) {

      socketContext.socket.on('onChatMessageReceived', (data) => {
        console.log('Message Received');
        if(selectedUserRef.current && selectedUserRef.current.id === data.message.chatId && activeViewRef.current === 'chats') {
          onMessageFirstLoadRef.current = true;
          setMessages(messages => messages.filter(m => m.id === data.message.id).length > 0 ? [...messages] : [...messages, data.message]);
          const existingChats = chatsRef.current;
          const updatedChats = existingChats.map(chat => {
            if(chat.id === data.message.chatId) {
              chat.LastMessage = data.message;
              chat.unreadMessages += 1;
            }
            return chat;
          });
          chatsRef.current = updatedChats;
          setUsers(updatedChats);
        }
        else if(selectedUserRef.current && selectedUserRef.current.id === data.message.groupId && activeViewRef.current === 'groups') {
          onMessageFirstLoadRef.current = true;
          setMessages(messages => messages.filter(m => m.id === data.message.id).length > 0 ? [...messages] : [...messages, data.message]);
          const existingGroups = groupsRef.current;
          const updatedGroups = existingGroups.map(group => {
            if(group.id === data.message.groupId) {
              group.LastMessage = data.message;
              group.unreadMessages += 1;
            }
            return group;
          });
          groupsRef.current = updatedGroups;
          setGroups(updatedGroups);
        }
        else if(data.message.chatId) {
          const existingChats = chatsRef.current;
          const updatedChats = existingChats.map(chat => {
            if(chat.id === data.message.chatId) {
              chat.LastMessage = data.message;
              chat.unreadMessages += 1;
            }
            return chat;
          });
          chatsRef.current = updatedChats;
          setUsers(updatedChats);
        }
        else if(data.message.groupId) {
          const existingGroups = groupsRef.current;
          const updatedGroups = existingGroups.map(group => {
            if(group.id === data.message.groupId) {
              group.LastMessage = data.message;
              group.unreadMessages += 1;
            }
            return group;
          });
          groupsRef.current = updatedGroups;
          setGroups(updatedGroups);
        }
      })

      socketContext.socket.on('onMarkRead', (data) => {
        if(data.error) {
          console.log('Error: ', data.error);
        } else {
          if(data.message.chatId) {
            const existingChats = chatsRef.current;
            const updatedChats = existingChats.map(chat => {
              if(chat.id === data.message.chatId) {
                chat.unreadMessages -= 1;
              }
              return chat;
            });
            chatsRef.current = updatedChats;
            setUsers(updatedChats);
          } else if(data.message.groupId) {
            const existingGroups = groupsRef.current;
            const updatedGroups = existingGroups.map(group => {
              if(group.id === data.message.groupId) {
                group.unreadMessages -= 1;
              }
              return group;
            });
            groupsRef.current = updatedGroups;
            setGroups(updatedGroups);
          }
          unReadMessagesRef.current -= 1;
          dispatch(setUnReadMessages(unReadMessagesRef.current));
          setMessages(messages => [...messages.map(message => {
            if(message.id === data.message.id) {
              message.isRead = true;
              message.timeRead = data.message.timeRead;
            }
            return message;
          })]);
        }
      })

      socketContext.socket.on('onMessageSend', (data) => {
        if(data.error) {
          console.log(data.error)
          toast.error(data.error);
        } else {
          onMessageFirstLoadRef.current = true;
          setMessages(messages => messages.filter(m => m.id === data.message.id).length > 0 ? [...messages] : [...messages, data.message]);
          if(data.message.chatId) {
            const existingChats = chatsRef.current;
            const updatedChats = existingChats.map(chat => {
              if(chat.id === data.message.chatId) {
                chat.LastMessage = data.message;
              }
              return chat;
            });
            chatsRef.current = updatedChats;
            setUsers(updatedChats);
          } else if(data.message.groupId) {
            const existingGroups = groupsRef.current;
            const updatedGroups = existingGroups.map(group => {
              if(group.id === data.message.groupId) {
                group.LastMessage = data.message;
              }
              return group;
            });
            groupsRef.current = updatedGroups;
            setGroups(updatedGroups);
          }
        }
      })

      socketContext.socket.on('onlineUsers', data => {
        console.log(data)
        setOnlineUsers(data.users)
      })

      socketContext.socket.emit('getOnlineUsers');
    }

  }, [socketContext.socket]);

  useEffect(() => {
    unReadMessagesRef.current = unReadMessages;
  }, [unReadMessages]);

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser])

  const loadChats = async () => {
    setIsLoading(true);
    const response = await sendGet(Urls.GetAllChats, true);
    if(response.error) {
      toast.error(response.error);
    } else {
      chatsRef.current = response.chats;
      setUsers(response.chats);
    }
    setIsLoading(false);
  }

  const loadGroups = async () => {
    setIsLoading(true);
    const response = await sendGet(Urls.GetUserGroups, true);
    if(response.error) {
      toast.error(response.error);
    } else {
      //If a group is already selected update it
      if(selectedUser && activeView === 'groups') {
        const searchedUsers = response.groups.filter(g => g.id === selectedUser.id);
        if(searchedUsers.length > 0) {
          setSelectedUser(searchedUsers[0])
        }
      }
      groupsRef.current = response.groups;
      setGroups(response.groups);
    }
    setIsLoading(false);
  }

  const loadUsers = async () => {
    setIsLoading(true);
    const response = await sendGet(Urls.GetUsers, true);
    if(response.error) {
      toast.error(response.error);
      console.log(response.error);
    } else {
      setUsersList(response.users);
    } 
    setIsLoading(false);
  }

  useEffect(() => {
    loadChats();
    loadGroups();
    loadUsers();
  }, []);

  useEffect(() => {
    if(activeView === 'groups' && selectedUser && usersList.length > 0 && subAccount) {
      const existingMembers = selectedUser.Members.map(member => member.id);
      setInviteableUsers(usersList.filter(user => !existingMembers.includes(user.id)));
    }
  }, [activeView, selectedUser, usersList, subAccount])

  const getOtherUser = (user) => {
    if(subAccount && user) {
      if(user.Sender && user.Sender.id !== subAccount.UserId) {
        return user.Sender;
      } else if(user.Receiver && user.Receiver.id !== subAccount.UserId) {
        return user.Receiver;
      }
    }
    return undefined;
  }

  const handleSearch = (event) => {
    let term = event.target.value;
    term = term.toLowerCase();
    setSearchTerm(term);
    console.log(users)
    // Filter users based on search term
    const filtered = users.filter((user) => {
      const otherUser = getOtherUser(user);
      return otherUser.firstName?.toLowerCase().includes(term) || otherUser.lastName?.toLowerCase().includes(term) 
    });
    setFilteredUsers(filtered);
  };

  useEffect(() => {
    if (messagesEndRef.current && onMessageFirstLoadRef.current) {
      if(messages.length > 0) {
        onMessageFirstLoadRef.current = false;
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    }
  }, [messages]);

  const handleGroupsearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Filter users based on search term
    const filtered = groups.filter((group) =>
      group.name.toLowerCase().includes(term.toLowerCase())
    );
    setfilteredGroups(filtered);
  };
  
  const handleSendMessage = async () => {
    if (newMessage.trim() || files.length > 0 || audioBlob) {
      const uploadedFiles = []
      for(let file of files) {
        const formData = new FormData();
        formData.append('file', file.file);
        const response = await sendPost(Urls.UploadChatFile, formData, true, 'multipart/form-data', false);
        if(response.error) {
          console.log(response.error)
        } else {
          uploadedFiles.push(response.file);
        }
      }

      if(socketContext.socket) {
        if(files.length > 0) {
          if(activeView === 'chats') {
            socketContext.socket.emit('sendMessage', {chatId: selectedUser.id, message: newMessage, files: uploadedFiles});
          } else if(activeView === 'groups') {
            socketContext.socket.emit('sendMessage', {groupId: selectedUser.id, message: newMessage, files: uploadedFiles});
          }
        } else {
          if(activeView === 'chats') {
            socketContext.socket.emit('sendMessage', {chatId: selectedUser.id, message: newMessage});
          } else if(activeView === 'groups') {
            socketContext.socket.emit('sendMessage', {groupId: selectedUser.id, message: newMessage});
          }
        }
      }
      setNewMessage("");
      setFiles([]);
      setAudioBlob(null);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const validFiles = selectedFiles.filter((file) => file.size <= maxSize);

    if (validFiles.length !== selectedFiles.length) {
      toast("Some files are too large. The maximum limit is 10 MB.");
    }

    if (validFiles.length > 0) {
      const newFiles = validFiles.map((file) => ({
        file,
        fileUrl: URL.createObjectURL(file),
      }));

      // Append new files to the existing list
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const deleteGroup = async () => {
    setIsLoading(true);
    const response = await sendDelete(Urls.DeleteGroup(selectedUser.id), {}, true);
    if(response.error) {
      toast.error(response.error);
      console.log(response.error);
    } else {
      setMessages([]);
      setIsSettingsVisible(false)
      toggleDeleteModal();
      setSelectedUser(undefined)
      loadGroups();
    }
    setIsLoading(false);
  };

  const toggleInviteModal = () => {
    setSelectedUsersToInvite([]);
    setShowInviteModal(!showInviteModal);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleLeaveGroup = () => {
    setShowLeaveModal(!showLeaveModal);
  }

  const leaveGroup = () => {
    toggleLeaveGroup();
  };
  
  const onConfirmGroupLeave = async () => {
    if(selectedUser && activeView === 'groups') {
      setIsLoading(false);
      const response = await sendDelete(Urls.LeaveGroup(selectedUser.id), {}, true);
      if(response.error) {
        console.error(response.error);
        toast.error(response.error);
      } else {
        setIsSettingsVisible(false)
        setSelectedUser(undefined);
        selectedUserRef.current = undefined;
        loadGroups();
        toggleLeaveGroup();
      }
      setIsLoading(true);
    }
  }

  const confirmInvite = async () => {
    const userIds = selectedUsersToInvite.map(index => inviteableUsers[index].id);
    setIsLoading(true);
    const response = await sendPost(Urls.InviteUserToGroup(selectedUser.id), {userIds: userIds}, true, 'application/json', true);
    if(response.error) {
      toast.error(response.error);
      console.log(response.error);
    } else {
      toast.success('Users invited to the group successfully');
      toggleInviteModal();
      // toggleSettingsVisibility();
      loadGroups();
    }
    setIsLoading(false);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const removeAudio = () => {
    setAudioBlob(null);
  };

  const renderMessageContent = (message) => {
    const onMediaClick = (e, fileType, url) => {
      if(fileType === 'image' || fileType === "video") {
        e.stopPropagation()
        e.preventDefault()
        setPreviewFile({fileType: fileType, url: url});
        setIsPreviewModalShown(true);
      }
    }

    return (
      <>
        {
          message.Files && message.Files.length > 0 && (
            <div className="media-container">
              {
                message.Files.map((file, index) => {
                  const fileType = file.type;
                  let fileName = '';
                  if(fileType === 'other') {
                    const fileParts = file.url.split('\\');
                    fileName = fileParts[fileParts.length - 1];
                  }
                  return (
                      <div className='file-container' onClick={e => onMediaClick(e, fileType, file.url)}>
                        {
                          fileType === "image" ?
                              <LazyLoadImage
                                  key={index}
                                  src={file.url}
                                  alt="Uploaded"
                                  height={300}
                                  width={300}
                                  className="chat-image"
                              /> :
                              (
                                  fileType === "video" ?
                                      <video
                                          key={index}
                                          src={file.url}
                                          controls
                                          className="chat-video"
                                      /> :
                                      (
                                          fileType === "audio" ?
                                              <audio controls src={file.url} className="chat-audio"/> :
                                              <a
                                                  key={index}
                                                  href={file.url}
                                                  download={fileName}
                                                  className="chat-file"
                                              >
                                                {fileName}
                                              </a>
                                      )
                              )
                        }
                      </div>
                  )
                })
              }
            </div>
          )
        }
        {
          message.text && (
            <span className="text">{message.text}</span>
          )
        }
      </>
    )
  };

  const handleChatPage = () => {
    loadChats();
    setGroupPage(false);
    setSearchCommunity(false);
    setSeachUser(false);
    setChatPage(true);
  };

  const handleGroupPage = () => {
    loadGroups();
    setGroupPage(true);
    setSearchCommunity(false);
    setSeachUser(false);
    setChatPage(true);
    setActiveView('groups');
  };

  const handleNewGroupClick = () => {
    setGroupPage(true);
    setChatPage(false);
  };

  const handleNewChat = () => {
    setSeachUser(true);
    setSearchCommunity(false);
    setChatPage(false);
  };

  const handleFindGroupsClick = () => {
    setSearchCommunity(true);
    setChatPage(false);
  };

  const dispatch = useDispatch();
  dispatch(setTitle(t("community_page")));

  const toggleSettingsVisibility = () => {
    setIsSettingsVisible(!isSettingsVisible);
    // document.querySelector(".setting-main").classList.toggle("to-center");
  };

  const handleUserSelect = async (user) => {
    setSelectedUser(user);
    try {
      setIsLoading(true);
      currentOffset.current = 0;
      onMessageFirstLoadRef.current = true;
      const response = await sendGet(Urls.GetMessagesForChat(user.id, currentOffset.current, MESSAGES_COUNT), true);
      if(response.error) {
        setMessages([]);
        console.log('Failed to Fetch Messages: ', response.error);
      } else {
        setHasMoreMessages(response.hasMore);
        setMessages(response.messages);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setMessages([]);
    }
    setIsLoading(false)
  };

  const loadMoreMessages = async () => {
    try {
      setIsLoadingMessages(true);
      currentOffset.current += MESSAGES_COUNT + 1;

      let url = Urls.GetMessagesForChat(selectedUser.id, currentOffset.current, MESSAGES_COUNT);

      if(activeView === 'groups')
        url = Urls.GetGroupMessages(selectedUser.id, currentOffset.current, MESSAGES_COUNT)

      const response = await sendGet(url, true);
      if(response.error) {
        console.log('Failed to Fetch Messages: ', response.error);
      } else {
        console.log(response)
        setHasMoreMessages(response.hasMore);
        setMessages([...response.messages, ...messages]);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
    setIsLoadingMessages(false)
  }

  const handleBackToUser = () => {
    setSelectedUser(undefined)
  };

  const handleGroupSelect = async (user) => {
    setSelectedUser(user);
    try {
      currentOffset.current = 0;
      onMessageFirstLoadRef.current = true;
      const response = await sendGet(Urls.GetGroupMessages(user.id, currentOffset.current, MESSAGES_COUNT), true);
      if(response.error) {
        setMessages([]);
        console.log('Failed to Fetch Messages: ', response.error);
      } else {
        console.log(response)
        setHasMoreMessages(response.hasMore);
        setMessages(response.messages);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setMessages([]);
    }
  };

  const changeActiveView = (view) => {
    setActiveView(view);
    setSelectedUser(undefined)
    setMessages([]);
    setIsSettingsVisible(false);
  }

  const onGroupUpdate = () => {
    loadGroups();
  }

  const onGroupJoin = () => {
    loadGroups();
    setActiveView('groups')
    setSearchCommunity(false);
    setChatPage(true);
  }

  const onUserRemove = () => {
    loadGroups();
  }

  useEffect(() => {
    inView('.messages-container .received', ({target}) => {
      const id = parseInt(target.dataset.id);
      const message = messages.filter(m => m.id === id)[0];
      if(!message.isRead && socketContext.socket) {
        socketContext.socket.emit('markRead', {messageId: message.id})
      }
    })
  }, [messages, socketContext.socket])

  const onPreviewModalClose = () => {
    setPreviewFile({fileType: "", url: ""});
    setIsPreviewModalShown(false);
  }

  const getOnlineMembersForAGroup = (members) => {
    let count = 0;
    console.log(members)
    if(members) {
      count = members.filter(member => onlineUsers.includes(member.id)).length;
    }
    return count;
  }

  const toggleChatDeleteModal = () => {
    setIsChatDeleteModalShown(!isChatDeleteModalShown);
  }

  const deleteChat = async () => {
    setIsLoading(true);
    const response = await sendDelete(Urls.DeleteChat(selectedUser.id), {}, true);
    if(response.error) {
      toast.error(response.error);
      console.log(response.error);
    } else {
      setMessages([]);
      setIsSettingsVisible(false)
      toggleChatDeleteModal();
      setSelectedUser(undefined)
      await loadChats();
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader text={"Loading data..."} fullScreen={true} />
      ) : (
          <ViewPermission permission={permissions.PAGE.COMMUNITY}>
            <div className={`page community-page ${theme.getThemeClass()}`}>
              <div className="main-container">
                <div className="page-body">
                  {chatPage && (
                      <div className="horizontal-container">
                        <div className={`users-contianer ${selectedUser ? 'hide' : ""}`}>
                          <div className="user-search-container">
                            <div className="search-bar">
                              {activeView === "chats" ? (
                                  <div className="search-center">
                                    <MdSearch/>
                                    <input
                                        placeholder={t("community.chatBar.searchPlaceholder")}
                                        type="search"
                                        id="user-search"
                                        name="user-search"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                  </div>
                              ) : (
                                  <div className="search-center">
                                    <MdSearch/>
                                    <input
                                        placeholder={t("community.chatBar.searchPlaceholder")}
                                        type="search"
                                        id="user-search"
                                        name="user-search"
                                        value={searchTerm}
                                        onChange={handleGroupsearch}
                                    />
                                  </div>
                              )}
                              <MdChat/>
                            </div>
                          </div>
                          <div className="btn-container">
                            <button
                                className={`chat-btn ${
                                    activeView === "chats" ? "active-view" : ""
                                }`}
                                onClick={() => changeActiveView("chats")}
                            >
                              <MdOutlinePerson/> {t("community.chatBar.chatsButton")}
                            </button>
                            <button
                                className={`group-btn ${
                                    activeView !== "chats" ? "active-view" : ""
                                }`}
                                onClick={() => changeActiveView("groups")}
                            >
                              <MdOutlinePeople/> {t("community.chatBar.groupsButton")}
                            </button>
                          </div>

                          {activeView === "chats" ? (
                              <ViewPermission permission={permissions.FEATURES.COMMUNITY_CHAT}>
                                <User
                                    handleNewChat={handleNewChat}
                                    handleChatPage={handleChatPage}
                                    handleUserSelect={handleUserSelect}
                                    selectedUser={selectedUser}
                                    users={searchTerm ? filteredUsers : users}
                                    onlineUsers={onlineUsers}
                                />
                              </ViewPermission>
                          ) : (
                              <ViewPermission permission={permissions.FEATURES.COMMUNITY_GROUP}>
                                <GroupChat
                                    handleFindGroupsClick={handleFindGroupsClick}
                                    handleNewGroupClick={handleNewGroupClick}
                                    handleGroupsearch={handleGroupsearch}
                                    handleGroupSelect={handleGroupSelect}
                                    groups={searchTerm ? filteredGroups : groups}
                                />
                              </ViewPermission>
                          )}
                        </div>
                        <div
                            className={`chat-container ${selectedUser ? 'show' : ""} ${isSettingsVisible ? "visible-width" : ""}`
                            }
                        >
                          <div>
                            <div className="user-name-header">
                              <div className="chat-name-container">
                                <div className="backtouser">
                                  <p className="back" onClick={handleBackToUser}>
                                    <MdOutlineArrowBack/>
                                  </p>
                                  <p className="chat-user-name">
                                    {
                                      activeView === 'chats' && selectedUser ?
                                          `${getOtherUser(selectedUser)?.firstName} ${getOtherUser(selectedUser)?.lastName}` :
                                          selectedUser?.name
                                    }
                                  </p>
                                </div>
                                {
                                  activeView === 'groups' && selectedUser ?
                                      <div className='horizontal-container'>
                                        <p className="members-number">
                                    <span
                                        className="members">{selectedUser.Members?.length} {t("community.chat.members")}</span>
                                        </p>
                                        <p>-</p>
                                        <p className="members-number">
                                    <span
                                        className="members">{getOnlineMembersForAGroup(selectedUser.Members)} {t("community.chat.onlineMembers")}</span>
                                        </p>
                                      </div> : <></>
                                }
                              </div>
                              {
                                selectedUser ?
                                    <div className="more" onClick={toggleSettingsVisibility}>
                                      <MdMoreVert/>
                                    </div> : <></>
                              }
                            </div>
                          </div>
                          <div ref={messagesEndRef} className="messages-container">
                            {messages.length > 0 ? (
                                <>
                                  {
                                    hasMoreMessages ?
                                        <div className="load-more-container">
                                          {
                                            isLoadingMessages ?
                                                <Loader height={20} barWidth={3}/> :
                                                <div className="primary-btn" onClick={loadMoreMessages}>
                                                  <MdArrowUpward/>
                                                  Load More
                                                </div>
                                          }
                                        </div> : <></>
                                  }
                                  {
                                    messages.map((message) =>
                                            message.sender !== subAccount.UserId ? (
                                                <motion.div key={message.id} className="received" data-id={message.id}
                                                            initial={{
                                                              scale: message.Files && message.Files.length > 0 ? 1 : 0,
                                                            }}
                                                            whileInView={{
                                                              scale: 1
                                                            }} viewport={{once: true}}>
                                                  <div className="sender">
                                                    <LazyLoadImage className="sender-img"
                                                                   src={message.Sender?.profileImage ?? ImagePlaceholder}
                                                                   alt="Sender"/>
                                                  </div>
                                                  <div className="message-container">
                                                    <div className="message-cont">
                                      <span>
                                        <MessageLeftCorner/>
                                      </span>
                                                      <div className="message-content">
                                                        {renderMessageContent(message)}
                                                      </div>
                                                    </div>
                                                    <span className="timestamp">
                                      {moment(message.sentTime).format("DD-MM-YYYY")}
                                    </span>
                                                  </div>
                                                </motion.div>
                                            ) : (
                                                <motion.div key={message.id} className="sent"
                                                            initial={{
                                                              scale: message.Files && message.Files.length > 0 ? 1 : 0,
                                                            }}
                                                            whileInView={{
                                                              scale: 1
                                                            }} viewport={{once: true}}>
                                                  <div className="message-container">
                                                    <div className="message-cont">
                                                      <div className="message-content">
                                                        {renderMessageContent(message)}
                                                      </div>
                                                      <span>
                                        <MessageRightCorner/>
                                      </span>
                                                    </div>
                                                    <span className="timestamp">
                                      {moment(message.sentTime).format("DD-MM-YYYY")}
                                    </span>
                                                  </div>
                                                  <div className="sender">
                                                    <LazyLoadImage className="sender-img"
                                                                   src={message.Sender?.profileImage ?? ImagePlaceholder}
                                                                   alt="Sender"/>
                                                  </div>
                                                </motion.div>
                                            )
                                    )
                                  }
                                </>
                            ) : (
                                <div className="start-conversation">
                                  <img src="message.png" alt=""/>
                                  <p>{t("community.chat.startAConversation")}</p>
                                </div>
                            )}
                          </div>

                          {
                            selectedUser ?
                                <div className="send-message-containers">
                                  {files.length !== 0 ? (
                                      <div className="file-gallery">
                                        {files.map((file, index) => (
                                            <div key={index} className="file-placeholder">
                                              {file.file.type.startsWith("image") ? (
                                                  <img src={file.fileUrl} alt="Selected"/>
                                              ) : file.file.type.startsWith("video") ? (
                                                  <video src={file.fileUrl} controls/>
                                              ) : (
                                                  <a
                                                      href={file.fileUrl}
                                                      download={file.file.name}
                                                  >
                                                    {file.file.name}
                                                  </a>
                                              )}
                                              <MdClose onClick={() => removeFile(index)}/>
                                            </div>
                                        ))}
                                      </div>
                                  ) : (
                                      ""
                                  )}

                                  <div className="your-message">
                                    <div id="upload-form">
                                      <label htmlFor="fileUpload">
                                        <MdOutlineUploadFile/>
                                      </label>
                                      <input
                                          className="hidden"
                                          type="file"
                                          id="fileUpload"
                                          name="fileUpload"
                                          onChange={handleFileChange}
                                          multiple
                                      />
                                      <input
                                          className="hidden"
                                          type="submit"
                                          value="Upload"
                                      />
                                    </div>
                                    <input placeholder={t("community.chat.messagePlaceholder")}
                                           type="text"
                                           id="message"
                                           value={newMessage}
                                           onChange={(e) => setNewMessage(e.target.value)}
                                           onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                               e.preventDefault();
                                               handleSendMessage();
                                             }
                                           }}
                                    />
                                  </div>
                                  <div className="mic-send">
                                    <div onClick={handleSendMessage}>
                                      <Send/>
                                    </div>
                                  </div>
                                </div> : <></>
                          }
                        </div>

                        <div className="setting-main">
                          <div
                              className={
                                isSettingsVisible
                                    ? "settings-container visible "
                                    : "settings-container"
                              }
                          >
                            {
                              activeView === 'groups' ?
                                  <GroupSettings
                                      type={activeView}
                                      selectedGroup={selectedUser}
                                      toggleInviteModal={toggleInviteModal}
                                      toggleDeleteModal={toggleDeleteModal}
                                      leaveGroup={leaveGroup}
                                      toggleSettingsVisibility={toggleSettingsVisibility}
                                      onGroupUpdate={onGroupUpdate}
                                  /> :
                                  <ChatSettings
                                      toggleSettingsVisibility={toggleSettingsVisibility}
                                      toggleDeleteModal={toggleChatDeleteModal}
                                  />
                            }
                            {
                              activeView === "groups" ?
                                  <GroupMembers selectedGroup={selectedUser} onUserRemove={onUserRemove}
                                                onlineUsers={onlineUsers}/> : <></>
                            }
                          </div>
                        </div>
                      </div>
                  )}
                  {searchCommunity && (
                      <SearchGroup handleChatPage={handleChatPage} onGroupJoin={onGroupJoin}/>
                  )}
                  {searchUser && (
                      <SearchUser
                          handleNewChat={handleNewChat}
                          handleChatPage={handleChatPage}
                      />
                  )}
                  {groupPage && <AddGroup handleChatPage={handleChatPage} handleGroupAdd={handleGroupPage}/>}
                </div>
              </div>
              {/* Invite Users Modal */}
              {showInviteModal && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="confirm-delete">
                        <h2 className="modal-heading">{t("community.inviteModal.header")}</h2>
                        <button
                            className="close-btn"
                            onClick={toggleInviteModal}
                            aria-label="Close"
                        >
                          &times;
                        </button>
                      </div>
                      <Select
                          options={inviteableUsers}
                          selectedIndex={selectedUsersToInvite}
                          allowMultiple={true}
                          onChange={(indices) => setSelectedUsersToInvite(indices)}
                          itemName="Users"
                          // selectedOptionBackground={"tag-bg-custom"}
                          isSearchable={true}
                          onSearch={(options, searchText) => {
                            return options.filter(option => option.firstName.toLowerCase().includes(searchText) || option.lastName.toLowerCase().includes(searchText) || option.email.toLowerCase().includes(searchText));
                          }}
                          onOptionRender={(option) => {
                            return (
                                <div className="horizontal-containerss">
                                  <img className="drop-img" src={option.profileImage ?? ImagePlaceholder} alt=""/>
                                  <div className="details">
                                    <p className="name">{`${option.firstName} ${option.lastName}`}</p>
                                    <span className="email ">{option.email}</span>
                                  </div>
                                </div>
                            );
                          }}
                          onSelectedOptionRender={(option) => {
                            return (
                                <div className="horizontal-containerss">
                                  <MdOutlinePerson/>
                                  {`${option.firstName} ${option.lastName}`}
                                </div>
                            );
                          }}
                      />
                      <div className="modal-buttons">
                        <button
                            className="confirm-invite-btn"
                            onClick={confirmInvite}
                        >
                          {t("community.inviteModal.confirmBtn")}
                        </button>
                        <button className="cancel-btn" onClick={toggleInviteModal}>
                          {t("community.inviteModal.cancelBtn")}
                        </button>
                      </div>
                    </div>
                  </div>
              )}

              {/* Delete Group Modal */}
              {showDeleteModal && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="confirm-delete">
                        <h2>{t("community.deleteModal.header")}</h2>

                        <button
                            className="close-btn"
                            onClick={toggleDeleteModal}
                            aria-label="Close"
                        >
                          &times;
                        </button>
                      </div>
                      <p>{t("community.deleteModal.message")}</p>
                      <div className="modal-buttons">
                        <button className="danger-btn" onClick={deleteGroup}>
                          {t("community.deleteModal.deleteBtn")}
                        </button>
                        <button className="secondary-btn" onClick={toggleDeleteModal}>
                          {t("community.deleteModal.cancelBtn")}
                        </button>
                      </div>
                    </div>
                  </div>
              )}

              {/* Delete Chat Modal */}
              {isChatDeleteModalShown && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="confirm-delete">
                        <h2>Delete Chat</h2>

                        <button className="close-btn"
                                onClick={toggleChatDeleteModal}
                                aria-label="Close">
                          &times;
                        </button>
                      </div>
                      <p>This action is <strong>permanent</strong> and cannot be reversed?</p>
                      <div className="modal-buttons">
                        <button className="danger-btn" onClick={deleteChat}>
                          Confirm
                        </button>
                        <button className="secondary-btn" onClick={toggleChatDeleteModal}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
              )}

              {/* Group Leave Modal */}
              {showLeaveModal && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="confirm-delete">
                        <h2>{t("community.leaveModal.header")}</h2>

                        <button
                            className="close-btn"
                            onClick={toggleDeleteModal}
                            aria-label="Close"
                        >
                          &times;
                        </button>
                      </div>
                      <p>{t("community.leaveModal.message")}</p>
                      <div className="modal-buttons">
                        <button className="danger-btn" onClick={onConfirmGroupLeave}>
                          {t("community.leaveModal.leaveBtn")}
                        </button>
                        <button className="secondary-btn" onClick={toggleLeaveGroup}>
                          {t("community.leaveModal.cancelBtn")}
                        </button>
                      </div>
                    </div>
                  </div>
              )}

              <FilePreviewModal isShown={isPreviewModalShown} onClose={onPreviewModalClose}
                                fileType={previewFile.fileType} fileUrl={previewFile.url}/>
            </div>
          </ViewPermission>
      )}
    </>
  );
}
