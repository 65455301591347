import "../../styles/InnerPage.scss";
import { useEffect, useState } from "react";
import { sendGet, sendPost } from "../../../common/request";
import links from "../../../common/links";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";
import useSubAccount from "../../hooks/useSubAccount";

function DailyGoalsPage() {
  let [goal, setGoal] = useState({});
  let [savedGoal, setSavedGoal] = useState({});
  let [canSave, setCanSave] = useState(false);
  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();
  const theme = useTheme();
  const subAccount = useSubAccount();

  let loadGoal = async () => {
    setError("");
    setIsLoading(true);
    let response = await sendGet(links.GetGoal(subAccount.id), true);
    if (!response.error) {
      setGoal(response.goal);
      setSavedGoal(response.goal);
    }
    else {
      setGoal({});
      setSavedGoal({});
    }
    setIsLoading(false);
  };

  let updateValue = (e) => {
    let {name, value} = e.target;
    const newGoal = {...goal};
    newGoal[name] = value;
    setGoal(newGoal);
  };

  useEffect(() => {
    if(subAccount) {
      loadGoal();
    }
  }, [subAccount]);

  useEffect(() => {
    if(goal && savedGoal) {
      if (goal.dailyProfitGoal === savedGoal.dailyProfitGoal && goal.dailyLossGoal === savedGoal.dailyLossGoal && goal.dailyTradeGoal === savedGoal.dailyTradeGoal && goal.maxTradedContracts === savedGoal.maxTradedContracts)
        setCanSave(false);
      else setCanSave(true);
    }
  }, [goal]);

  let discardChanges = () => {
    setGoal(savedGoal);
  };

  let saveGoal = async () => {

    if(!canSave) {
      return;
    }
    setError('')
    setIsLoading(true);
    let response = await sendPost(
      links.SetGoal(subAccount.id),
      {
        dailyProfitGoal: goal.dailyProfitGoal,
        dailyLossGoal: goal.dailyLossGoal,
        dailyTradeGoal: goal.dailyTradeGoal,
        maxTradedContracts: goal.maxTradedContracts,
      },
      true, "application/json", true
    );

    if (response.error) {
      setError(response.error);
    } else {
      setGoal(response.goal)
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className={`data-page ${theme.getThemeClass()}`}>
          <div className="data-page-body">
              <div className="profile-data">
                <div className="heading">{t("settings.daily_goals.heading")}</div>
                <div className="fields">
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t("settings.daily_goals.daily_profit_goal")}</label>
                      <input
                          type="text"
                          name='dailyProfitGoal'
                          value={goal?.dailyProfitGoal}
                          placeholder="Set daily profit goal"
                          onChange={(e) => updateValue(e)}
                          className="input-group-item"
                      />
                    </div>
                    <div className="input-group">
                      <label>{t("settings.daily_goals.daily_loss_goal")}</label>
                      <input
                          type="text"
                          name='dailyLossGoal'
                          value={goal?.dailyLossGoal}
                          placeholder="Set daily loss goal"
                          onChange={(e) => updateValue(e)}
                          className="input-group-item"
                      />
                    </div>
                    <div className="input-group">
                      <label>{t("settings.daily_goals.daily_trade_goal")}</label>
                      <input
                          type="text"
                          name='dailyTradeGoal'
                          value={goal?.dailyTradeGoal}
                          placeholder="Set daily trade goal"
                          onChange={(e) => updateValue(e)}
                          className="input-group-item"
                      />
                    </div>
                    <div className="input-group">
                      <label>Max Traded Contracts</label>
                      <input
                          type="text"
                          name='maxTradedContracts'
                          value={goal?.maxTradedContracts}
                          placeholder="Set daily trade goal"
                          onChange={(e) => updateValue(e)}
                          className="input-group-item"
                      />
                    </div>
                  </div>
                  {error ? <div className="error mt-2">{error.toString()}</div> : <></>}
                </div>
                <div className="action-btn">
                  <div className={`primary-outline-btn ${canSave ? "" : "disabled"}`} onClick={saveGoal}>
                    {t("save_btn_text")}
                  </div>
                  {canSave ? (
                      <div
                          className="danger-outline-btn"
                          onClick={discardChanges}
                      >
                        {t("discard_btn_text")}
                      </div>
                  ) : (
                      <></>
                  )}
                </div>
              </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DailyGoalsPage;
