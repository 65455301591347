// Local = http://localhost:3001
// Remote = https://trading-prozess-backend.vercel.app

let baseLink = process.env.REACT_APP_BACKEND_URL;
const getSocketLink = () => {
  return process.env.REACT_APP_WEBSOCKET_URL;
}

export {
  getSocketLink
}

function getLink(link) {
  return `${baseLink}/${link}`;
}

let links = {
  Login: getLink("login"),
  Register: getLink("register"),
  Token: getLink("validate-token"),
  GetUser: getLink("get-logged-in-user"),
  Profile: getLink("profile"),
  GetUsers: getLink('getusers'),
  SubAccounts: getLink("sub-accounts"),
  UpdateEconomicCalendar: getLink('economic-calendar/settings'),
  GetEconomicCalendar: getLink('economic-calendar'),
  EditSubAccount: (id) => getLink(`sub-accounts/${id}`),
  ClearTrades: (id) => getLink(`sub-accounts/${id}/trades`),
  GetTrades: (subUserId) => getLink(`history-my-trades/${subUserId}`),
  SearchTrades: (subUserId, query) => getLink(`history-my-trades/search/${subUserId}?${new URLSearchParams(query)}`),
  UploadTrades: getLink("history-my-trades"),
  DeleteTrade: (id) => getLink(`history-my-trades/${id}`),
  ShareTrade: (id, subAccountId) => getLink(`history-my-trades/share/${subAccountId}/${id}`),
  StopShareTrade: (id, subAccountId) => getLink(`history-my-trades/share/stop/${subAccountId}/${id}`),
  GetSharedTrade: (id, subAccountId) => getLink(`history-my-trades/share/trade/${subAccountId}/${id}`),
  GetDayInsights: (subAccountId) => getLink(`history-my-trades/insights/${subAccountId}`),
  ChangePassword: getLink(`password/change`),
  Tags: getLink("tags"),
  GetTags: getLink("tags/all"),
  AssignTags: getLink("tags/assign"),
  TagTrades: getLink("tags/trades"),
  UploadProfileImage: getLink("profile/image"),
  ClearProfileImage: getLink("profile/image/clear"),
  SendTwoFactorCode: getLink(`two-factor-code`),
  VerifyCode: getLink("verify-code"),
  ResetPassword: getLink("reset-password"),
  GetTrade: (id, subAccountId) => getLink(`history-my-trades/${id}/${subAccountId}`),
  UpdateTrade: (id, subAccountId) => getLink(`history-my-trades/${id}/${subAccountId}`),
  GetFolders: getLink("notebook/folders/all"),
  CreateFolder: getLink("notebook/folders"),
  UpdateFolder: (id) => getLink(`notebook/folders/${id}`),
  DeleteFolder: (id) => getLink(`notebook/folders/${id}`),
  GetFolderNotes: (id) => getLink(`notebook/folders/notes/${id}`),
  GetNotebookNote: (id) => getLink(`notebook/notes/${id}`),
  GetAllNotebookNotes: getLink(`notebook/notes/all`),
  CreateOrUpdateNote: (id) => getLink(`notebook/notes/${id}`),
  GetNotesOfType: (type) => getLink(`notebook/notes/type/${type}`),
  GetNoteForDate: (date, subAccountId) => getLink(`notebook/notes/date/${date}/${subAccountId}`),
  GetNoteForTrade: (tradeId, subAccountId) => getLink(`notebook/notes/trade/${tradeId}/${subAccountId}`),
  GetNoteForSharedTrade: (tradeId, subAccountId) => getLink(`notebook/notes/trade/shared/${tradeId}/${subAccountId}`),
  GetNotesForTag: (tagId) => getLink(`notebook/notes/tag/${tagId}`),
  DeleteNotebookNote: (id) => getLink(`notebook/notes/${id}`),
  DeleteNotebookNotes: getLink(`notebook/notes`),
  CreateNotebookTag: getLink("notebook/tags"),
  AssignNotebookTag: getLink("notebook/tags/assign"),
  GetNotebookTagsWithUsage: getLink("notebook/tags/usage"),
  GetNotebookTags: getLink("notebook/tags"),
  DeleteNoteBookTags: getLink("notebook/tags"),
  CreatePlaybook: getLink("playbook"),
  GetPlaybook: (id, accountId) => getLink(`playbook/${id}/${accountId}`),
  GetAllPlaybooks: (accountId) => getLink(`playbook/all/${accountId}`),
  GetPlaybooksList: (accountId) => getLink(`playbook/all/list/${accountId}`),
  UpdatePlaybook: (id) => getLink(`playbook/${id}`),
  DeletePlaybook: (id, accountId) => getLink(`playbook/${id}/${accountId}`),
  BulkDeletePlaybook: (accountId) => getLink(`playbook/${accountId}`),
  AttachPlaybookTrade: (playbookId, tradeId) => getLink(`playbook/attach/${playbookId}/${tradeId}`), 
  DetachPlaybookTrade: (playbookId, tradeId) => getLink(`playbook/detach/${playbookId}/${tradeId}`),
  UpdatePlaybookRules: (playbookId, tradeId) => getLink(`playbook/trade/${playbookId}/${tradeId}`),
  UploadChatFile: getLink(`chat/file`),
  CreateChat: getLink('chat/create'),
  GetAllChats: getLink('chat/all'),
  DeleteChat: (id) => getLink(`chat/${id}`),
  SendMessage: getLink('chat/message'),
  GetMessagesForChat: (chatId, start, count) => getLink(`chat/messages/${chatId}?start=${start}&count=${count}`),
  CreateGroup: getLink('chat/group/create'),
  GetAllGroups: getLink('chat/group/all'),
  GetUserGroups: getLink('chat/group/user'),
  GetAllUnreadMessagesCount: getLink(`chat/unread-messages-count`),
  InviteUserToGroup: (groupId) => getLink(`chat/group/${groupId}/invite`),
  UpdateGroup: (groupId) => getLink(`chat/group/${groupId}`),
  LeaveGroup: (groupId) => getLink(`chat/group/${groupId}/leave`),
  RemoveUserFromGroup: (groupId, userId) => getLink(`chat/group/${groupId}/remove/${userId}`),
  DeleteGroup: (groupId) => getLink(`chat/group/${groupId}`),
  GetGroupMessages: (groupId, start, count) => getLink(`chat/group/${groupId}/messages?start=${start}&count=${count}`),
  JoinGroup: (groupId) => getLink(`chat/group/${groupId}/join`),
  CreateCustomer: getLink(`subscription/customer/create`),
  CreateSession: getLink(`subscription/session/create`),
  CreatePaymentIntent: getLink(`subscription/payment-intent/create`),
  ConfirmPaymentIntent: (paymentIntentId) => getLink(`subscription/payment-intent/confirm/${paymentIntentId}`),
  UpdateSubscriptionRenewal: getLink(`subscription/renewal`),
  DowngradeSubscription: getLink(`subscription/downgrade`),
  CancelSubscriptionDowngrade: getLink(`subscription/downgrade/cancel`),
  GetGoal: (subAccountId) => getLink(`goal/${subAccountId}`),
  SetGoal: (subAccountId) => getLink(`goal/${subAccountId}`),
  GetDailyProfitGoalStatus: (subAccountId) => getLink(`goal/status/${subAccountId}`),
  GetBalanceChanges: (subAccountId) => getLink(`balance/changes/${subAccountId}`),
  GetPackages: getLink(`packages`),
  GetWeeklyTradingStats: (subAccountId) => getLink(`history-my-trades/stats/weekly/${subAccountId}`)
};

export default links;
