import "../../styles/InnerPage.scss";
import ImagePlaceholder from "../../images/image-placeholder.jpg";
import { MdCameraAlt, MdDelete } from "react-icons/md";
import { useEffect, useState } from "react";
import { sendDelete, sendGet, sendPost } from "../../../common/request";
import links from "../../../common/links";
import Loader from "../../components/Loader";
import { setUser as setReduxUser } from "../../../redux/userActions.js/action";
import { useDispatch } from "react-redux";
import Select from "../../components/selectcontrol";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import momentTimeZone from "moment-timezone";
import {getTimezoneOffset} from "../../utils/date";
import {useTheme} from "../../Providers/ThemeContext";
import ViewPermission from "../../components/permissions/ViewPermission";
import permissions from "../../data/Permissions.json";

function ProfilePage() {
  let [user, setUser] = useState({});
  let [savedUser, setSavedUser] = useState({});
  let [canSave, setCanSave] = useState(false);
  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  let dispatch = useDispatch();
  let { t } = useTranslation();
  const theme = useTheme();

  const themeOptions = ['light', "dark"]
  const timeZones = momentTimeZone.tz.names().map(timezone => {
    return {
      timezone: timezone,
      offset: getTimezoneOffset(timezone)
    }
  })
  const timeZoneNames = timeZones.map(tz => tz.timezone);

  let loadUser = async () => {
    setError("");
    setIsLoading(true);
    let response = await sendGet(links.GetUser, true);
    if (!response.error) {
      i18next.changeLanguage(response.user.language);
      setSavedUser(response.user);
      setUser(response.user);
      theme.changeTheme(response.user.theme);
      dispatch(
        setReduxUser({
          user: response.user,
        })
      );
    }
    setIsLoading(false);
  };

  let updateUsername = (value) => {
    let newUser = { ...user };
    newUser.username = value;
    setUser(newUser);
  };

  let updateEmail = (value) => {
    let newUser = { ...user };
    newUser.email = value;
    setUser(newUser);
  };

  let updateFirstName = (value) => {
    let newUser = { ...user };
    newUser.firstName = value;
    setUser(newUser);
  };

  let updateLastName = (value) => {
    let newUser = { ...user };
    newUser.lastName = value;
    setUser(newUser);
  };

  let updateTimezone = (value) => {
    let newUser = { ...user };
    newUser.timezone = value.timezone;
    setUser(newUser);
  }

  let updateTheme = (value) => {
    let newUser = { ...user };
    newUser.theme = value;
    setUser(newUser);
  }

  let profileVisibilities = ['Public', 'Private']
 
  let updateVisibility = (index) => {
    let newUser = { ...user };
    newUser.visibility = profileVisibilities[index];
    setUser(newUser);
  }

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (
      user.username === savedUser.username &&
      user.email === savedUser.email &&
      user.firstName === savedUser.firstName &&
      user.lastName === savedUser.lastName &&
      user.language === savedUser.language &&
      user.visibility === savedUser.visibility &&
      user.timezone === savedUser.timezone &&
      user.theme === savedUser.theme
    )
      setCanSave(false);
    else setCanSave(true);
  }, [user]);

  let discardChanges = () => {
    setUser(savedUser);
  };

  let saveProfile = async () => {

    if(!canSave) {
      return;
    }

    setIsLoading(true);
    let response = await sendPost(
      links.Profile,
      {
        id: savedUser.id,
        username: user.username,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        language: user.language,
        visibility: user.visibility,
        timezone: user.timezone,
        theme: user.theme,
      },
      true, "application/json", true
    );

    if (response.error) {
      setError(response.error);
      setIsLoading(false);
    } else {
      await loadUser();
    }
  };

  let uploadProfileImage = () => {
    document.getElementById("profileInput").click();
  };

  let onImageSelected = async (e) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let response = await sendPost(
      links.UploadProfileImage,
      formData,
      true,
      "multipart/form-data",
      false
    );
    if (response.error) {
      setError(response.error);
      setIsLoading(false);
    } else {
      loadUser();
    }
  };

  let clearImage = async (e) => {
    setIsLoading(true);
    let response = await sendDelete(links.ClearProfileImage, null, true);
    if (response.error) {
      setError(response.error);
    } else {
      loadUser();
    }
  };

  let languages = {
    en: "English",
    ar: "Arabic",
    de: "German",
    fr: "French",
  };

  let languageNames = ["English", "Arabic", "German", "French"];

  let onLanguageChange = (index) => {
    let languageAbbr = Object.keys(languages);
    let newUser = { ...user };
    newUser.language = languageAbbr[index];
    setUser(newUser);
  };

  return (
    <>
      {isLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className={`data-page ${theme.getThemeClass()}`}>
          <div className="data-page-body">
            <div className="profile-section">
              <div className="profile-image">
                {savedUser.profileImage ? (
                  <img src={savedUser.profileImage} />
                ) : (
                  <img src={ImagePlaceholder} />
                )}
                <div className="name">
                  {savedUser && savedUser?.firstName
                    ? `${savedUser.firstName}`
                    : `-`}{" "}
                  {savedUser && savedUser?.lastName
                    ? `${savedUser.lastName}`
                    : `-`}
                </div>
                <div className="action-btn">
                  <input
                    type="file"
                    hidden
                    id="profileInput"
                    onChange={(e) => onImageSelected(e)}
                  />
                  <div
                    className="primary-outline-btn"
                    onClick={uploadProfileImage}
                  >
                    <MdCameraAlt />
                  </div>
                  <div className="danger-outline-btn" onClick={clearImage}>
                    <MdDelete />
                  </div>
                </div>
              </div>
              <div className="profile-data">
                <div className="heading">{t("profile_page_heading")}</div>
                <div className="fields">
                  <div className="dual-row">
                    <div className="input-group">
                      <label>{t("profile_page_firstName")}</label>
                      <input
                          type="text"
                          value={user.firstName}
                          placeholder="Type First Name"
                          onChange={(e) => updateFirstName(e.target.value)}
                          className="input-group-item"
                      />
                    </div>
                    <div className="input-group">
                      <label>{t("profile_page_lastName")}</label>
                      <input
                          type="text"
                          placeholder="Type Last Name"
                          value={user.lastName}
                          onChange={(e) => updateLastName(e.target.value)}
                          className="input-group-item"
                      />
                    </div>
                  </div>
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t("profile_page_username")}</label>
                      <input
                          type="text"
                          placeholder="Type Username"
                          value={user.username}
                          onChange={(e) => updateUsername(e.target.value)}
                          className="input-group-item"
                      />
                    </div>
                  </div>
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t("profile_page_email")}</label>
                      <input
                          type="text"
                          placeholder="Type Email"
                          value={user.email}
                          onChange={(e) => updateEmail(e.target.value)}
                          className="input-group-item"
                      />
                    </div>
                  </div>
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t("profile_page_language")}</label>
                      <Select
                          options={languageNames}
                          itemName="Language"
                          selectedIndex={languageNames.indexOf(
                              languages[user.language]
                          )}
                          onChange={(i) => onLanguageChange(i)}
                          className="input-group-item"
                      />
                    </div>
                  </div>
                  <ViewPermission permission={permissions.FEATURES.PUBLIC_ACCOUNT_POSSIBILITY} shallHide={true}>
                    <div className="single-row">
                      <div className="input-group">
                        <label>{t('settings.profile.visibility')}</label>
                        <Select
                            options={profileVisibilities}
                            itemName="Visibility"
                            selectedIndex={profileVisibilities.indexOf(user.visibility)}
                            onChange={(i) => updateVisibility(i)}
                            className="input-group-item"
                        />
                      </div>
                    </div>
                  </ViewPermission>
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t('settings.profile.timezone')}</label>
                      <Select
                          options={timeZones}
                          selectedIndex={timeZoneNames.indexOf(user.timezone)}
                          onChange={(index) => updateTimezone(timeZones[index])}
                          onOptionRender={(item) => {
                            return (
                                <div>
                                  ({item.offset}) {item.timezone}
                                </div>
                            )
                          }}
                          onSelectedOptionRender={(item) => {
                            return (
                                <div>
                                  ({item.offset}) {item.timezone}
                                </div>
                            )
                          }}
                      />
                    </div>
                  </div>
                  <div className="single-row">
                    <div className="input-group">
                      <label>{t("settings.profile.theme")}</label>
                      <Select
                          options={themeOptions}
                          selectedIndex={themeOptions.indexOf(user.theme)}
                          onChange={(index) => updateTheme(themeOptions[index])}
                      />
                    </div>
                  </div>
                  {error ? <div className="error mt-2">{error.toString()}</div> : <></>}
                </div>
                <div className="action-btn">
                  <div
                      className={`primary-outline-btn ${
                          canSave ? "" : "disabled"
                      }`}
                      onClick={saveProfile}
                  >
                    {t("save_btn_text")}
                  </div>
                  {canSave ? (
                      <div
                          className="danger-outline-btn"
                          onClick={discardChanges}
                      >
                        {t("discard_btn_text")}
                      </div>
                  ) : (
                      <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePage;
