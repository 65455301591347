import bell from "../../images/bell-dynamic-color.png";
import chart from "../../images/chart-dynamic-color.png";
import money from "../../images/money-dynamic-color.png";
import pencil from "../../images/pencil-dynamic-color.png";
import thumbUp from "../../images/thumb-up-dynamic-color.png";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HeroSection = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div className='hero-section front' id={'home'}>
            <img src={bell} alt="Bell" className="bell bg-item"/>
            <img src={chart} alt="Bell" className="chart bg-item"/>
            <img src={pencil} alt="Bell" className="pencil bg-item"/>
            <img src={money} alt="Bell" className="money bg-item"/>
            <img src={thumbUp} alt="Bell" className="thumbUp bg-item"/>
            <div className="content">
                <h1 className="heading">
                    {t("landing_page.hero_section.title.part1")}<br/>{t("landing_page.hero_section.title.part2")}
                </h1>
                <p className='description'>
                    {t("landing_page.hero_section.description")}
                </p>
                <div className="horizontal-container">
                    {/*<div className="button outline-btn" onClick={() => navigate('pricing')}>*/}
                    {/*    {t("landing_page.hero_section.pricing_button")}*/}
                    {/*</div>*/}
                    <div className="button filled-btn" onClick={() => navigate('register')}>
                        {t("landing_page.hero_section.register_button")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;