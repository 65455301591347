import {useTranslation} from "react-i18next";
import AnalyticsDashboardImage from "../../../assets/images/feature-images/Analytics Dashboard.png";
import CommunityImage from "../../../assets/images/feature-images/Community.jpg";
import DailyJournalImage from "../../../assets/images/feature-images/Daily Journal.jpg";
import NotebookImage from "../../../assets/images/feature-images/Notebook.jpg";
import PlaybookImage from "../../../assets/images/feature-images/Playbook.jpg";
import ReportsImage from "../../../assets/images/feature-images/Reports.jpg";

const FeaturesSection = () => {

    const {t} = useTranslation();

    const features = [
        {
            heading: "Analytics Dashboard",
            description: "Get all your trading data in one place. The Analytics Dashboard provides comprehensive insights to optimize your trading strategies",
            image: AnalyticsDashboardImage,
            reversed: false,
            imagePosition: 'bottom left',
            objectPosition: "top right",
        },
        {
            heading: "Daily Journal",
            description: "Track your daily trading activities. The Daily Journal helps you document progress and identify patterns.",
            image: DailyJournalImage,
            reversed: true,
            imagePosition: 'top right',
            objectPosition: "bottom left",
        },
        {
            heading: "Focused Reports",
            description: "Generate detailed reports on your trading performance. Analyze your successes and find areas for improvement",
            image: ReportsImage,
            reversed: true,
            imagePosition: 'top center',
            objectPosition: "v-center h-center",
        },
        {
            heading: "Playbooks",
            description: "Create your own trading strategies in the Playbook. Save and optimize setups for future trades",
            image: PlaybookImage,
            reversed: false,
            imagePosition: 'bottom left',
            objectPosition: "v-center h-center",
        },
        {
            heading: "Notebooks",
            description: "Record your trading ideas and notes. Digital notebooks offer a flexible way to store everything important.",
            image: NotebookImage,
            reversed: false,
            imagePosition: 'bottom center',
            objectPosition: "v-center h-center",
        },
        {
            heading: "Community",
            description: "Join an active trading community. Connect with other traders and share your experiences",
            image: CommunityImage,
            reversed: true,
            imagePosition: 'top center',
            objectPosition: "v-center h-center",
        }
    ]

    return (
        <div className='features-section front'>
            <div className="content">
                <h1 className="heading">
                    {t("landing_page.features_section.title")}
                </h1>
                <div className="features">
                    {
                        features.map((feature, index) => {
                            return (
                                <div className={`feature ${feature.reversed ? 'reverse' : ''}`} key={index}>
                                    <div className="content">
                                        <div className="heading">{feature.heading}</div>
                                        <div className="description">{feature.description}</div>
                                    </div>
                                    <div className={`image ${feature.imagePosition}`}>
                                        <img src={feature.image} className={feature.objectPosition} alt={feature.heading}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FeaturesSection;