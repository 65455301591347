import "../styles/marquee.scss";
import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "../Providers/ThemeContext";

const Marquee = ({children}) => {

    const theme = useTheme()

    return (
        <div className={`marquee ${theme.getThemeClass()}`}>
            <div className="marquee-content">
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
            </div>
            <div aria-hidden={true} className="marquee-content">
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
                <div className="marquee-item">
                    {children}
                </div>
                <div className="separator"></div>
            </div>
        </div>
    )
}

export default Marquee;