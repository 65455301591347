import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getToken} from "../../common/token";

const AuthenticateUser = ({shallRedirect = false, children}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const token = getToken();

    const redirect = () => {
        if(shallRedirect) {
            const query = {
                redirect: location.pathname,
            }
            navigate(`/login?${new URLSearchParams(query)}`);
        }
    }

    useEffect(() => {
        if(!token) {
            redirect()
        }
    }, [token])

    return (
        <>
            {children}
        </>
    )
}

export default AuthenticateUser;