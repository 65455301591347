import { useEffect, useState } from "react";
import { sendGet, sendPost, sendDelete } from "../../../common/request";
import links from "../../../common/links";
import { MdEdit, MdDelete, MdCancel } from "react-icons/md";
import AccountEditModal from "../../components/Modal/AccountEditModal";
import { setUser } from "../../../redux/userActions.js/action";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import NotificationBar from "../../components/NotificationBar";
import { useTranslation } from "react-i18next";
import DataToolTip from "../../components/ToolTip";
import {useTheme} from "../../Providers/ThemeContext";
import {toast} from "react-toastify";
import currencyFormat from "../../../common/format";
import DeleteConfirmationModal from "../../components/Modal/DeleteConfirmationModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

function AccountsPage() {
  let [subAccounts, setSubAccounts] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [isModalLoading, setIsModalLoading] = useState(false);
  let [error, setError] = useState("");
  let [name, setName] = useState("");
  let [status, setStatus] = useState("");
  let [profitCalculationMethod, setProfitCalculationMethod] = useState("");
  let [broker, setBroker] = useState("");
  let [mode, setMode] = useState("");
  let [balance, setBalance] = useState(10000);
  let [accountToEdit, setAccountToEdit] = useState("");
  let dispatch = useDispatch();
  const {user} = useSelector(state => state.user);
  const theme = useTheme();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    shown: false,
    accountId: null
  });
  const [tradeClearModal, setTradeClearModal] = useState({
    shown: false,
    accountId: null
  })
  const [maxSubAccounts, setMaxSubAccounts] = useState(-1);

  let loadUser = async () => {
    let response = await sendGet(links.GetUser, true);
    if (!response.error) {
      dispatch(
        setUser({
          user: response.user,
        })
      );
    }
  };

  let handleCloseModal = () => {
    setName("");
    setStatus("");
    setProfitCalculationMethod("");
    setBroker("");
    setIsModalLoading(false);
    setMode("");
    setIsOpen(false);
  };

  let handleSaveModal = async () => {
    setIsModalLoading(true);
    setError('');
    if (mode === "Add") {
      let response = await sendPost(
        links.SubAccounts,
        {
          name: name,
          profitMethod: profitCalculationMethod,
          broker: broker,
          balance: balance,
        },
        true
      );

      if (response.error) {
        setError(response.error);
      } else {
        await loadSubAccounts();
        toast.success("Account Added Successfully 🎉");
        handleCloseModal();
      }
    } else {
      setIsModalLoading(true);
      let response = await sendPost(
        links.EditSubAccount(accountToEdit.id),
        {
          name: name,
          profitMethod: profitCalculationMethod,
          broker: broker,
          status: status,
          balance: balance,
        },
        true
      );

      if (response.error) {
        setError(response.error);
      } else {
        await loadSubAccounts();
        toast.success("Account Updated Successfully 🎉");
        handleCloseModal();
      }
    }
    await loadUser();
    await loadSubAccounts();
    setIsModalLoading(false);
  };

  let loadSubAccounts = async () => {
    setIsLoading(true);
    let response = await sendGet(links.SubAccounts, true);
    console.log(response);
    if (!response.error) {
      setSubAccounts(response.subAccounts);
    }
    setIsLoading(false);
  };

  let openModal = (mode, account) => {
    if (mode !== "Add") {
      setAccountToEdit(account);
      setName(account.name);
      setBroker(account.broker);
      setProfitCalculationMethod(account.ProfitCalculationMethod);
      setStatus(account.status);
      setBalance(account.balance);
    }
    setMode(mode);
    setIsOpen(true);
  };

  let confirmADeleteAccount = async () => {
    const id = deleteConfirmationModal.accountId;
    if(id) {
      setIsLoading(true);
      let response = await sendDelete(links.EditSubAccount(id), "", true);
      if (!response.error) {
        await loadSubAccounts();
        await loadUser();
        toast.success("Account deleted Successfully 🎉");
      } else {
        toast.error(response.error)
      }
      setDeleteConfirmationModal({
        shown: false,
        accountId: null
      })
      setIsLoading(false);
    }
  };

  let cancelDeleteAccount = () => {
    setDeleteConfirmationModal({
      shown: false,
      accountId: null
    })
  }

  let deleteAccount = (id) => {
    setDeleteConfirmationModal({
      shown: true,
      accountId: id
    })
  }

  let confirmClearTrades = async () => {
    const id = tradeClearModal.accountId;
    if(id) {
      setIsLoading(true);
      let response = await sendDelete(links.ClearTrades(id), "", true);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Trades Cleared from the account successfully 🎉");
      }
      setTradeClearModal({
        shown: false,
        accountId: null
      })
      setIsLoading(false);
    }
  }

  let cancelClearTrades = () => {
    setTradeClearModal({
      shown: false,
      accountId: null
    })
  }

  let clearTrades = async (id) => {
    setTradeClearModal({
      shown: true,
      accountId: id
    })
  };

  useEffect(() => {
    loadSubAccounts();
  }, []);

  let { t } = useTranslation();

  useEffect(() => {
    if(user && user.user && user.user.subscription) {
      const permissions = user.user.subscription.permissions;
      permissions.forEach(permission => {
        if(permission.startsWith('feature.max-accounts.')) {
          const portions = permission.split('.');
          const maxAccounts = portions[portions.length - 1];
          if(maxAccounts === 'unlimited') {
            setMaxSubAccounts(-1);
          }
          else {
            setMaxSubAccounts(parseInt(maxAccounts));
          }
        }
      })
    }
  }, [user])

  return (
    <>
      {
        deleteConfirmationModal.shown ?
            <ConfirmationModal heading={'Delete Account Confirmation'} message={'Are you sure you want to delete the account. This action is irreversible. All of the data related to an account will be deleted'}
                                     handleConfirmModal={confirmADeleteAccount} handleCloseModal={cancelDeleteAccount} isDelete={true} />
            : <></>
      }
      {
        tradeClearModal.shown ?
            <ConfirmationModal heading={'Clear Account Trades Confirmation'} message={'Are you sure you want to clear the trades of the account. This action is irreversible.'}
                               handleConfirmModal={confirmClearTrades} handleCloseModal={cancelClearTrades} isDelete={true} />
            : <></>
      }
      {
        isLoading ? (
          <Loader fullScreen={true} />
        ) : (
          <div className={`data-page accounts-page ${theme.getThemeClass()}`}>
            <div className="page-header">
              <div className="title">
                {t("accounts_page_heading")}
                {
                  maxSubAccounts === -1 ?
                      <p className="info">You can create unlimited accounts</p> :
                      <p className="info">You can have upto {maxSubAccounts} accounts</p>
                }
              </div>
              <div className="primary-btn" onClick={() => openModal("Add")}>
                {t("add_account_btn_text")}
              </div>
            </div>
            <div className="data-page-body">
              <div className={`table ${theme.getThemeClass()}`}>
                <div className="grid-col-5 header">
                  <div className="item">{t("accounts_page_label_name")}</div>
                  <div className="item">
                    {t("accounts_page_label_broker")}
                  </div>
                  <div className="item">
                    {t("accounts_page_label_profit_calculation_method")}
                  </div>
                  <div className="item">
                    {t("accounts_page_label_status")}
                  </div>
                  <div className="item">
                    Balance
                  </div>
                  <div></div>
                </div>
                {subAccounts.map((acc, index) => {
                  return (
                      <div className="row">
                        <div className="item">{acc.name}</div>
                        <div className="item">
                          {acc.broker ? acc.broker : "-"}
                        </div>
                        <div className="item">
                          {acc.ProfitCalculationMethod
                              ? acc.ProfitCalculationMethod
                              : "-"}
                        </div>
                        <div className="item">
                          {acc.status ? acc.status : "-"}
                        </div>
                        <div className="item">
                          {acc.balance ? currencyFormat.format(acc.balance) : "-"}
                        </div>
                        <div className="item action-btn">
                          <DataToolTip message={"Edit Account"}>
                            <div
                                className="text-btn"
                                onClick={() => openModal("Edit", acc)}
                            >
                              <MdEdit/>
                            </div>
                          </DataToolTip>
                          <DataToolTip message={"Clear Account Trades"}>
                            <div
                                className="text-btn"
                                onClick={() => clearTrades(acc.id)}
                            >
                              <MdCancel/>
                            </div>
                          </DataToolTip>
                          <DataToolTip message={"Delete Account"}>
                            <div
                                className="text-danger-btn"
                                onClick={() => deleteAccount(acc.id)}
                            >
                              <MdDelete/>
                            </div>
                          </DataToolTip>
                        </div>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>
        )
      }
      <AccountEditModal
        name={name}
        mode={mode}
        broker={broker}
        profitCalculationMethod={profitCalculationMethod}
        status={status}
        setBroker={setBroker}
        setName={setName}
        setProfitCalculationMethod={setProfitCalculationMethod}
        setStatus={setStatus}
        balance={balance}
        setBalance={setBalance}
        isLoading={isModalLoading}
        isOpen={isOpen}
        error={error}
        handleCloseModal={handleCloseModal}
        handleSave={handleSaveModal}
      />
    </>
  );
}

export default AccountsPage;
