import { MdCached, MdClose, MdSell } from "react-icons/md";
import "../../styles/tags-modal.scss";
import Select from "../selectcontrol";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

const TagsModal = ({
  isOpen,
  handleSave,
  handleCloseModal,
  name,
  type,
  setName,
  setType,
  description,
  setDescription,
  error,
  isLoading,
}) => {
  let types = ["Setup", "Mistake", "Custom"];
  let [canCreate, setCanCreate] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (types.indexOf(type) != -1 && name) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [name, type]);

  let { t } = useTranslation();

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal modal-sm ${theme.getThemeClass()}`}>
            <div className="modal-header">
              <div className="title">{t("tag_modal_heading")}</div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name">{t("tag_modal_name_label")}:</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t("tag_modal_name_placeholder")}
                  className="w-full"
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">{t("tag_modal_type_label")}:</label>
                <Select
                  options={types}
                  selectedIndex={types.indexOf(type)}
                  onChange={(index) => setType(types[index])}
                  itemName="Tag Type"
                  onOptionRender={(option) => {
                    return (
                      <div className={`horizontal-container`}>
                        <div className={`${option.toLowerCase()}`}>
                          <MdSell />
                        </div>
                        {option}
                      </div>
                    )
                  }}
                  onSelectedOptionRender={(option) => {
                    return (
                      <div className={`horizontal-container`}>
                        <div className={`${option.toLowerCase()}`}>
                          <MdSell />
                        </div>
                        {option}
                      </div>
                    )
                  }}
                />
              </div>
              <div className="form-group description-box">
                <label htmlFor="description">
                  {t("tag_modal_description_label")}:
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  id="description"
                  placeholder={t("tag_modal_description_placeholder")}
                />
              </div>
              {error ? <div className="form-group error">{error}</div> : <></>}
            </div>
            <div className="modal-footer">
              <button className="danger-btn" onClick={handleCloseModal}>
                {t("tag_modal_cancel_btn")}
              </button>
              {isLoading ? (
                <Loader height={20} barWidth={3} />
              ) : (
                <button
                  className={`primary-btn ${canCreate ? "" : "disabled"}`}
                  onClick={() => {
                    if (canCreate) {
                      handleSave();
                    }
                  }}
                >
                  {t("tag_modal_create_btn")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsModal;
