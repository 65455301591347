import {useState} from "react";
import {useTranslation} from "react-i18next";

const FAQSection = () => {

    const {t} = useTranslation();
    const [faqs, setFAQs] = useState([
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.1.question"),
            answer: t("landing_page.faq_section.faqs.1.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
        {
            question: t("landing_page.faq_section.faqs.0.question"),
            answer: t("landing_page.faq_section.faqs.0.answer"),
            selected: false,
        },
    ])

    const onFAQSelected = (index) => {
        const newFAQs = faqs.map((faq, i) => {
            if(i === index) {
                faq.selected = !faq.selected;
            }
            else {
                faq.selected = false;
            }
            return faq;
        });
        setFAQs(newFAQs);
    }

    return (
        <div className="faq-section front">
            <div className="content">
                <div className="title">
                    <div className="heading">
                        <span className='bold'>{t("landing_page.faq_section.title.before")} <span className="gradient">{t("landing_page.faq_section.title.gradient")}</span></span>
                        <span>{t("landing_page.faq_section.title.after")}</span>
                    </div>
                    <div className="description">
                        {t("landing_page.faq_section.description")}
                    </div>
                </div>
                <div className="faqs">
                    {
                        faqs.map((faq, index) => {
                            return (
                                <div className={`faq ${faq.selected ? 'expanded' : ''}`} key={index} onClick={() => onFAQSelected(index)}>
                                    <div className="header">
                                        <div className="heading">{faq.question}</div>
                                        <div className="icon">
                                            <div className="line line-1"></div>
                                            <div className="line line-2"></div>
                                        </div>
                                    </div>
                                    <div className="body">{faq.answer}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQSection;