import { MdOutlineAccountBox, MdSettings } from "react-icons/md";
import "../styles/settings-sidebar.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";

function SettingsSidebar() {
    let [tab, setTab] = useState("");
    let navigate = useNavigate();
    let {t} = useTranslation();
    const theme = useTheme();

    let location = useLocation();
    useEffect(() => {
        if(location.pathname === "/settings/manage/tags")
            setTab("tags")
        else if(location.pathname === "/settings/profile")
            setTab("profile");
        else if(location.pathname === "/settings/accounts")
            setTab('accounts');
        else if(location.pathname === "/settings/security")
            setTab('security');
        else if(location.pathname ==='/settings/economic-calendar')
            setTab('economic-calendar');
        else if(location.pathname ==='/settings/subscription')
            setTab('subscription');
        else if (location.pathname === '/settings/daily-goals')
            setTab('dailyGoals');
    }, [location.pathname])

    return (
        <div className={`inner-sidebar ${theme.getThemeClass()}`}>
            <div className="section">
                <div className="section-header">
                    <MdOutlineAccountBox />
                    {t('settings_user_section_heading')}
                </div>
                <div className={`section-item ${tab === 'profile' ? "active" : ""}`} onClick={() => navigate("/settings/profile")}>
                    {t('settings_profile_page')}
                </div>
                <div className={`section-item ${tab === 'security' ? "active" : ""}`} onClick={() => navigate("/settings/security")}>
                    {t('settings_security_page')}
                </div>
                <div className={`section-item ${tab === "economic-calendar" ? "active" : ""}`} onClick={() => navigate('/settings/economic-calendar')}>
                    {t("settings_economic_calendar_page")}
                </div>
            </div>
            <div className="section">
                <div className="section-header">
                    <MdSettings/>
                    {t('settings_general_section_heading')}
                </div>
                <div className={`section-item ${tab === 'accounts' ? "active" : ""}`}
                     onClick={() => navigate("/settings/accounts")}>
                    {t('settings_accounts_page')}
                </div>
                <div className={`section-item ${tab === 'tags' ? "active" : ""}`}
                     onClick={() => navigate("/settings/manage/tags")}>
                    {t('settings_tags_page')}
                </div>
                <div className={`section-item ${tab === 'dailyGoals' ? "active" : ""}`}
                     onClick={() => navigate("/settings/daily-goals")}>
                    {t("settings.pages.daily_goals")}
                </div>
                <div className={`section-item ${tab === "subscription" ? "active" : ""}`}
                     onClick={() => navigate('/settings/subscription')}>
                    {t("settings.pages.subscription")}
                </div>
            </div>
        </div>
    )
}

export default SettingsSidebar;